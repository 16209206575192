import {CLEAR_STORE, GET_MODAL} from '../types';

const modal_reducer = (state = {}, action) => {
    switch (action.type) {
        case GET_MODAL:
        case CLEAR_STORE:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
}

export {
    modal_reducer
}