import {FANPAGE_POST_REVIEW_EXPORT_SUCCESS} from "../../../types";


/**
 * @param {*} state
 * @param {*} action
 */
const PostReviewExportReducer = (state = {}, action) => {

    switch (action.type) {
        case FANPAGE_POST_REVIEW_EXPORT_SUCCESS:
            return {...state, action: action};
        default:
            return {...state, action: action};
    }
};

export {
    PostReviewExportReducer
}