import {
    RESEND_MESSAGES_ERROR,
    RESEND_MESSAGES_SUCCESS,
    SEND_MESSAGES_STATUS_SUCCESS, SUMMARY_SEND_MESSAGES_ERROR,
    SUMMARY_SEND_MESSAGES_SUCCESS
} from "../../../types";
import {toast} from "react-toastify";
import {i18n} from "../../../language/i18n";


/**
 * @param {*} state
 * @param {*} action
 */
const SendMessagesStatusReducer = (state = [], action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case SEND_MESSAGES_STATUS_SUCCESS:
            return {...state, action: action};
        case RESEND_MESSAGES_SUCCESS:
            return {...state, action: action};
        case RESEND_MESSAGES_ERROR:
            let message = action.payload.message;
            toast.error(i18n.t(message));
            return {...state, action};
        case SUMMARY_SEND_MESSAGES_SUCCESS:
            return {...state, action: action};
        case SUMMARY_SEND_MESSAGES_ERROR:
            let message_summary = action.payload.message;
            toast.error(i18n.t(message_summary));
            return {...state, action};
        default:
            return state;
    }
};

export {
    SendMessagesStatusReducer
}