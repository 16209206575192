import {SERVER_API_PLATFORMJK} from '../../constans/index';
import {
    TRIGGER_SUMMARY_STOP_REQUEST,
    TRIGGER_SUMMARY_STOP_SUCCESS,
    TRIGGER_SUMMARY_STOP_FAILURE,
} from '../../types/index';
import {RSAA} from 'redux-api-middleware';
import headers from "../../utils/headers";

const SummaryStopTriggerAction = (id) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_PLATFORMJK}/api/triggers/${id}/summary-stop`,
        method: 'PUT',
        headers: (headers()),
        types: [
            TRIGGER_SUMMARY_STOP_REQUEST,
            {
                type: TRIGGER_SUMMARY_STOP_SUCCESS,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            data = json.data
                        });
                        return {
                            "code": 0,
                            "type": TRIGGER_SUMMARY_STOP_SUCCESS,
                            "data": data
                        }
                    }

                }
            },
            {
                type: TRIGGER_SUMMARY_STOP_FAILURE,
                payload: async (action, state, res) => {

                    let message = "";
                    await res.json().then((json) => {
                        message = json.message;
                    });

                    return {
                        "code": 1,
                        "type": TRIGGER_SUMMARY_STOP_FAILURE,
                        "message": message,
                        "data": [],
                    }
                }
            }
        ]
    }

});

export {SummaryStopTriggerAction}