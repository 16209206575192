import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {headers} from '../../../utils/index';
import {RSAA} from 'redux-api-middleware';
import {FAILURE_NAME_FANPAGE_FUNBOT, GET_NAME_FANPAGE_FUNBOT, REQUEST_NAME_FANPAGE_FUNBOT} from "../../../types/index";

const GetNameFanPageFacebookAction = (pageId, actor = null) => ({

    [RSAA]: {
        endpoint: () => {
            return `${SERVER_API_PLATFORMJK}/api/v2/name-page-facebook?id_page_facebook=${pageId}`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            REQUEST_NAME_FANPAGE_FUNBOT,
            {
                type: GET_NAME_FANPAGE_FUNBOT,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            data = json.data
                        });

                        return {
                            "code": 0,
                            "type": GET_NAME_FANPAGE_FUNBOT,
                            "data": data,
                            "actor": actor,
                        }
                    }
                }
            },
            {
                type: FAILURE_NAME_FANPAGE_FUNBOT,
                payload: (action, state, res) => {
                    return res.json()
                },
            },

        ]
    }
});

export {GetNameFanPageFacebookAction}