import {
    TELE_USER_UPDATE_SUCCESS,
} from "../../../types";

/**
 * @param {*} state
 * @param {*} action
 */
const TeleUserReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {

        case TELE_USER_UPDATE_SUCCESS:
            let id = action.payload.data['id'];
            state[id] = action.payload.data;
            return {...state, action};
        default:
            return state;
    }
};

export {TeleUserReducer}