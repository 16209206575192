import issetFiled from "./issetFiled";

/**
 |--------------------------------------------------
 | Headers
 |--------------------------------------------------
 */
export default function headers() {
    const token_type = issetFiled('token_type') ? JSON.parse(localStorage.getItem('token_type')) : null;
    const access_token = issetFiled('access_token') ? JSON.parse(localStorage.getItem('access_token')) : null;
    // const token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjNiMjBjZDQyZjM0Yzg2NzljNDE5ZmIxYjA4ZWZmN2I5NjVmYTUxNWE0OGVjOGQxZGYwODY5ZGNjMDNiMDg1ODEzODU5YjQwYmQxYjZhN2Y0In0.eyJhdWQiOiIxIiwianRpIjoiM2IyMGNkNDJmMzRjODY3OWM0MTlmYjFiMDhlZmY3Yjk2NWZhNTE1YTQ4ZWM4ZDFkZjA4NjlkY2MwM2IwODU4MTM4NTliNDBiZDFiNmE3ZjQiLCJpYXQiOjE3MDE5MzI5NjcsIm5iZiI6MTcwMTkzMjk2NywiZXhwIjoxNzAyMTA1NzY2LCJzdWIiOiIxIiwic2NvcGVzIjpbXSwidXNlciI6eyJpZCI6MSwibmFtZSI6IkhpXHUxZWJmdSIsImVtYWlsIjoiaGlldXR0QGZ1bnRhcC52biIsImVtYWlsX3ZlcmlmaWVkX2F0IjpudWxsLCJzdGF0dXMiOjEsImNyZWF0ZWRfYXQiOiIyMDIyLTEwLTEzIDE1OjIxOjIwIiwidXBkYXRlZF9hdCI6IjIwMjItMTAtMTMgMTU6MjE6MjAiLCJjb21wYW5pZXMiOltdfX0.IDnZFm_mrV8igAcxtmXlLrPhKwj0Hpfl3iL8azGricUTDlTBvOdVwm9S9IpfhYyUaM65ykzUMbH8-TWxx0hoBemAsmpjig8UNgaOjp2-IqKr1fRwBpTkFr2kfD-LwGrKiTcVFjq7bpjZ_v7A23cy6t08CjPkaPA_N1QNQCluy_LuZE8lV30Mc5GQ5peDCW-SFHe2XBOBgBGPTWdvrUlqCCwACSqcS3ybdmYBz9RyfZSihUrmjDVb63YO3Pzii9Hqam7cJHkINndhy2x36rptzG4EfzOTE_fNovlczLD_41F6RA8JKJ-k-vFFmsP1YTqgMKUc21qN9HJvWlBgMxyoKdSFcnbNM3BT3H9va2emAemBdKJ61e8zAUojhPnCIGVLi8mutL9_6pO3jay18b0jM8HCq0zOUp1ryp50j4w6oqqIcSA_6HvGPFe2QqDY71qujJpsTxnKsaJl83jiEmZt4x1nUA4pgQFKYI9nUzujzR-KneiSw1wA6h9JL_PHHV33kOhHZAJiBi2q8XWB226H6J8NxhJKZU-cgcnPJr7114X8J2UmcHYaNQFYN2IeGKNDho8D9oNeXS9osYPZCLtpxkpoQE5Q9GukNhU5xYeP3CHYOKZT9-0xRRYGhapr8TGUM8Yzt-Dj-iazyBdLwNJ7utLprDjj88h2dj2GqajwS_o'
    const token = token_type + ' ' + access_token;
    return {"Content-Type": "application/json", "Accept": "application/json", "Authorization": token};
}