import {FANPAGE_POST_SUMMARY_SUCCESS} from "../../../types";


/**
 * @param {*} state
 * @param {*} action
 */
const PostsSummaryReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case FANPAGE_POST_SUMMARY_SUCCESS:
            return {...state, action: action};
        default:
            return {...state, action: action};
    }
};

export {
    PostsSummaryReducer
}