const en = {
    "login": "Login",
    "email": "Email",
    "password": "Password",
    "register": "Register",
    "sign_in_to_your_account": "Sign In to your account",
    "register_now": "Register now",
    "email_is_required": "Email is required.",
    "email_is_incorrect": "Email is incorrect.",
    "password_must_be_at_least_6_characters": "Password must be at least 10 characters.",
    "account_does_not_have_access": "Account does not have access.",
    "create_your_account": "Create your account.",
    "user_name": "User Name",
    "repeat_password": "Repeat password",
    "create_account": "Create Account",
    "dashboard": "Dashboard",
    "facebook": "Facebook",
    "fanpage": "Fanpage",
    "fb_app": "Fb app",
    "fb_keyword": "Fb keyword",
    "endpoint": "Endpoint",
    "notify": "Notify",
    "facebook_notify": "Facebook notify",
    "sdk_notify": "Sdk notify",
    "admin": "Admin",
    "users": "Users",
    "roles": "Roles",
    "permission": "Permission",
    "conversation": "Conversation",
    "home": "Home",
    "create": "Create",
    "edit": "Edit",
    "detail": "Detail",
    "profile": "Profile",
    "web_notify": "Web notify",
    "page_name": "Page Name",
    "search": "Search",
    "id": "ID",
    "message": "Message",
    "image": "Image",
    "page_id_from_facebook": "Page ID from Facebook",
    "status": "Status",
    "action": "Action",
    "no_data": "No data",
    "not_found": "Not found",
    "app_name": "App name",
    "app_id_from_facebook": "App id from facebook",
    "keyword": "Keyword",
    "do_you_want_to_delete": "Do you want to delete",
    "ok": "Ok",
    "cancel": "Cancel",
    "method": "Method",
    "url": "Url",
    "name": "Name",
    "registered": "Registered",
    "roles_name": "Roles name",
    "admin_id": "Admin id",
    "client_id": "Client id",
    "admin_responded": "Admin responded",
    "all": "All",
    "true": "True",
    "false": "False",
    "platform": "Platform",
    "admin_started": "Admin started",
    "start_message": "Start message",
    "last_message": "Last message",
    "update": "Update",
    "view": "View",
    "endpoint_and_press_enter": "Endpoint and press enter",
    "keyword_and_press_enter": "Keyword and press enter",
    "user_and_press_enter": "User and press enter",
    "page_name_is_required": "Page name is required!",
    "page_id_from_facebook_is_required": "Page id from facebook is required!",
    "edit_keywords_success": "Edit keywords success",
    "edit_endpoints_success": "Edit endpoints success",
    "delete_endpoints_success": "Delete endpoints success",
    "edit_success": "Edit success",
    "create_success": "Create success",
    "type_keyword_and_press_enter": "Type keyword and press enter",
    "submit": "Submit",
    "headers": "Headers",
    "queries": "Queries",
    "settings": "Settings",
    "logout": "Logout",
    "user_name_is_required": "User name is required!",
    "user_name_are_limited_to_6_to_14_characters": "User name are limited to 6 to 14 characters!",
    "password_is_required": "Password is required!",
    "you_need_to_confirm_the_password": "You need to confirm the password!",
    "password_does_not_match": "Password does not match!",
    "keyword_is_required": "Keyword is required!",
    "url_is_required": "Url is required!",
    "method_is_required": "Method is required!",
    "please_enter_the_correct_url_format": "Please enter the correct url format!",
    "format_error_queries": "Format error queries!",
    "format_error_headers": "Format error headers!",
    "no_suggestions": "No suggestions!",
    "please_enter_the_correct_email_format_@funtap.vn": "Please enter the correct email format @(funtap.vn|techlab.ai|9pay.vn)",
    "upload_file_success": "Upload file success !",
    "file_is_not_supported_file_extension": "File is not supported file extension !",
    "max_image_size_2MB": "Max image size 2MB !",
    "fanpage_not_found": "Fanpage Not Found",
    "company": "Company",
    "getStarted": "Get Started",
    "Register success": "Register success",
    "Title": "Title",
    "post_id": "ID",
    "post_id_from_facebook": "ID from Facebook",
    "scan_stop_at": "Scan stop",
    "actions": "Actions",
    "scan_status": "Status",
    "page_id": "ID",
    "hear": "Hear",
    "hear_id": "ID",
    "select_post_error": "select post please",
    "select_post":"Select Post",
    "app_id": "ID",
    "int_app_id_from_partner": "App Id",
    "app_os": "Os",
    "segment_id": "ID",
    "app_key": "App Key",
    "app_partner_name": "Game",
    "app_id_from_partner": "Game",
    "collection_id_from_partner": "Collection",
    "collect_time": "Collect Time",
    "collect_status": "Collect Status",
    "collect_count": "Collected",
    "notify_time": "Notify Time",
    "notify_status": "Notify Status",
    "notify_sent": "Sent",
    "notify_success": "Success",
    "notify_error": "Error",
    "notify_total_time": "Time (minutes)",
    "notify_speed": "Speed (minutes)",
    "partners": "Created by",

    //Email Notify
    "domain": "Domain",
    "notify_title": "Email Title",
    "The notify title field is required.": "The Email Title field is required.",
    "template": "Template",
    "email_count": "Emails",
    "approve_telegram_group": "Approve Telegram Group",
    "email_blacklist": "Email Blacklist",
    "blacklisted_domain_ids": "Blacklisted domain ids",
    "blacklisted_domains": "Blacklisted domains",
    "whitelisted_domain_ids": "Whitelisted domain ids",
    "whitelisted_domains": "Whitelisted domains",
    "is_blacklisted_all": "Blacklisted all",
    "_id": "ID",
    "blacklist": "Blacklist",
    "whitelist": "Whitelist",
    "for_all": "For all",
    "yes": "Yes",
    "no": "No",
    "Update Email Blacklist success": "Update Email Blacklist success",
    "requested_domain_ids": "Requested domain ids",
    "requested_domain": "Requested domains",
    "blacklisting_domain_ids": "Blacklisting domain ids",
    "blacklisting_domains": "Blacklisting domains",
    "whitelisting_domain_ids": "Whitelisting domain ids",
    "whitelisting_domains": "Whitelisting domains",
    "is_for_all": "For all",
    "email_blacklist_logs": "Email Blacklist Logs",
    "requested_ip": "Requested IP",
    "group_id": "Group ID",
    "admins": "Admins",
    "chat_id": "Chat ID",

    //Short Link
    "short_link": "Short Link",
    "short_link_users": "Short Link Users",
    "full_link": "Full Link",
    "creator": "Creator",
    'abilities': 'Abilities',
    'assign': 'Assign',
    'retract': 'Retract',
    'tracking_count': 'Tracking Count',

    //Report Logs
    "api": "Api",
    "type_api": "Type Api",
    "status_code": "Status Code",
    "service": "Service",
    "total": "Total Use",
    "Status report api": "Status report api",
    "Sort total api": "Sort total api",
    "Time start report": "Time start report",
    "Time end report": "Time end report",

    //Logs
    "log_name": "log Name",
    "input_param": "Input Param",
    "properties": "Properties",
    "created_at": "Created At",

    //Push Notify
    "trigger_id":"ID",
    "type":"Type",
    "trigger_at":"Date Send",

    //Google Reply
    "game_name":"Game Name",
    "package_name":"Package Name",
    "game_type":"Game Type",

    "approve_status":"Approve Status",

    // Ems
    "fmt_message":"Text",
    "send_time":"Time",
    "response":"Result",
    "creator_id":"Creator",
    "message_status":"Send Status",
    "message_sent":"Sent",
    "message_success":"Success",
    "message_error":"Failed",
    "group_name":"Group",
    "chat_name":"Chat",
    "tele_user":"User",
    "total_users":"Total Users",

    "is_online":"Status",

    "late_3_hours":"Late 3 Hours",
    "late_24_hours":"Late 24 Hours",
    "total_conversations":"Total Conversations",
    "total_messages":"Total Messages",

    "total_done":"Conv done",
    "total_assign":"Conv assign",
    "total_not_done":"Conv processing",
    "total_late_3h":"Conv late 3h",
};

export {en}