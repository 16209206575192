import {UPLOAD_IMGUR_FAILURE, UPLOAD_IMGUR_REQUEST, UPLOAD_IMGUR_SUCCESS} from '../../types/index';
import {RSAA} from 'redux-api-middleware';

const UploadImgurAction = (file) => ({

    [RSAA]: {
        endpoint: "https://api.imgur.com/3/image",
        method: 'POST',
        headers: {Authorization: "Client-ID 5788b6149417818"},
        body: file,
        types: [
            UPLOAD_IMGUR_REQUEST,
            {
                type: UPLOAD_IMGUR_SUCCESS,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            data = json.data
                        });

                        return {
                            "code": 0,
                            "type": UPLOAD_IMGUR_SUCCESS,
                            "data": data
                        }
                    }

                }
            },
            {
                type: UPLOAD_IMGUR_FAILURE,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        return res.json()
                    }
                },
            },
        ]
    }

});

export {UploadImgurAction}