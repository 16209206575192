import {
    FANPAGE_POST_REVIEW_GIFTCODE_TRACKING_SUCCESS, FANPAGE_POST_REVIEW_RESEND_GIFTCODE_ERROR,
    FANPAGE_POST_REVIEW_RESEND_GIFTCODE_SUCCESS, SUMMARY_SEND_MESSAGES_ERROR
} from "../../../types";
import {toast} from "react-toastify";
import {i18n} from "../../../language/i18n";


/**
 * @param {*} state
 * @param {*} action
 */
const SendGiftCodeTrackingReducer = (state = [], action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case FANPAGE_POST_REVIEW_GIFTCODE_TRACKING_SUCCESS:
            return {...state, action: action};
        case FANPAGE_POST_REVIEW_RESEND_GIFTCODE_SUCCESS:
            return {...state, action: action};
        case FANPAGE_POST_REVIEW_RESEND_GIFTCODE_ERROR:
            let message = action.payload.message;
            toast.error(i18n.t(message));
            return {...state, action};
        default:
            return state;
    }
};

export {
    SendGiftCodeTrackingReducer
}