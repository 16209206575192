import {SERVER_API_PLATFORMJK} from '../../constans';
import {RSAA} from 'redux-api-middleware';
import {GET_DETAIL_USERS} from "./../../types/index";
import headers from "../../utils/headers";

const get_detail_user_action = () => ({

     [RSAA]: {
        endpoint: `${SERVER_API_PLATFORMJK}/api/users`,
        method: 'GET',
        headers: (headers()),
        types: [
            'REQUEST_DETAIL_USERS',
            {
                type: GET_DETAIL_USERS,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        return res.json()
                    }

                }
            },
            'FAILURE_DETAIL_USERS'
        ]
     }

})

export {get_detail_user_action}