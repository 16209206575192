import {SERVER_API_FB_BOT} from '../../../constans';
import {
    SEND_GIFT_CODE_REQUEST,
    SEND_GIFT_CODE_SUCCESS,
    SEND_GIFT_CODE_FAILURE,
} from '../../../types/index';
import {RSAA} from 'redux-api-middleware';
import headers from "../../../utils/headers";

const SendGiftCodeAction = (postId, data_target) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_FB_BOT}/api/posts/${postId}/giftcodes`,
        method: 'POST',
        headers: (headers()),
        body: (() => {
            return JSON.stringify(data_target);
        }),
        types: [
            SEND_GIFT_CODE_REQUEST,
            {
                type: SEND_GIFT_CODE_SUCCESS,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            data = json.data
                        });
                        return {
                            "code": 0,
                            "type": SEND_GIFT_CODE_SUCCESS,
                            "data": data
                        }
                    }

                }
            },
            {
                type: SEND_GIFT_CODE_FAILURE,
                payload: async (action, state, res) => {

                    let message = "";
                    await res.json().then((json) => {
                        message = json.message;
                    });

                    return {
                        "code": 1,
                        "type": SEND_GIFT_CODE_FAILURE,
                        "message": message,
                        "data": [],
                    }
                }
            }
        ]
    }

});

export {SendGiftCodeAction}