import {apiMiddleware} from 'redux-api-middleware';
import jwt_decode from 'jwt-decode';
import {REFRESH_TOKEN, FAILURE_TOKEN} from './types';
import {refresh_access_token_action, is_access_token_expired_action} from './actions';
import {issetFiled, redirect_page} from "./utils";
import {trackPromise} from "react-promise-tracker";

export function createApiMiddleware() {
    let postponedRSAAs = []

    return ({dispatch, getState}) => {
        const rsaaMiddleware = apiMiddleware({dispatch, getState})

        return (next) => (action) => {

            const nextCheckPostoned = (nextAction) => {
                // Run postponed actions after token refresh
                if (nextAction.type === REFRESH_TOKEN) {
                    next(nextAction);
                    postponedRSAAs.forEach((postponed) => {
                        rsaaMiddleware(next)(postponed)
                    })
                    postponedRSAAs = []

                } else if (nextAction.type === FAILURE_TOKEN) {
                    localStorage.clear();
                    sessionStorage.clear();
                    redirect_page('/login');
                } else {
                    next(nextAction)
                }
            }

            // if (isRSAA(action)) {
            let user_info = issetFiled('refresh_token') ? JSON.parse(localStorage.getItem('user_auth2')) : null;
            let user_name = user_info ? user_info.email : null;
            let refresh_token = issetFiled('refresh_token') ? JSON.parse(localStorage.getItem('refresh_token')) : null;
            let access_token = issetFiled('access_token') ? JSON.parse(localStorage.getItem('access_token')) : null;

            // if (refresh_token && access_token && is_access_token_expired_action(jwt_decode(access_token).exp)) {
            if (access_token && is_access_token_expired_action(jwt_decode(access_token).exp)) {
                postponedRSAAs.push(action)
                if (postponedRSAAs.length === 1) {
                    return rsaaMiddleware(nextCheckPostoned)(refresh_access_token_action(refresh_token,user_name))
                } else {
                    return
                }
            }
            let resultPro = rsaaMiddleware(next)(action);

            if(resultPro instanceof Promise)
                trackPromise(
                    resultPro
                );
            return resultPro;
            //return rsaaMiddleware(next)(action);
            //}

            // return next(action);
        }
    }
}

export default createApiMiddleware();
