import {
    GAME_UPDATE_SUCCESS,
    GAME_UPDATE_FAILURE,
    GAME_CREATE_SUCCESS,
    GAME_CREATE_FAILURE,
    GAME_DETAIL_SUCCESS,
    GAME_DETAIL_FAILURE,
    GOOGLE_REPLY_SUCCESS,
    GOOGLE_REPLY_FAILURE,
    GOOGLE_REVIEW_SUCCESS,
    GOOGLE_REVIEW_FAILURE,
    GOOGLE_REPLY_DETAIL_SUCCESS,
    GOOGLE_REPLY_DETAIL_FAILURE,
} from '../types';

/**
 * @param {*} state
 * @param {*} action
 */
const GameReducer = (state = {}, action) => {

    switch (action.type) {
        case GAME_UPDATE_SUCCESS:
        case GAME_UPDATE_FAILURE:
        case GAME_CREATE_SUCCESS:
        case GAME_CREATE_FAILURE:
        case GAME_DETAIL_SUCCESS:
        case GAME_DETAIL_FAILURE:
        case GOOGLE_REPLY_SUCCESS:
        case GOOGLE_REPLY_FAILURE:
        case GOOGLE_REVIEW_SUCCESS:
        case GOOGLE_REVIEW_FAILURE:
        case GOOGLE_REPLY_DETAIL_SUCCESS:
        case GOOGLE_REPLY_DETAIL_FAILURE:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export {
    GameReducer
}