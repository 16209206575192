import {STATUS_DELETE_ENDPOINT_PAGE} from "../../../types";

const status_delete_endpoint_page_action = (id_fanpage) => {
    return {
        type: STATUS_DELETE_ENDPOINT_PAGE,
        payload: {
            code:0,
            type:'delete_endpoint_page_success',
            id_fanpage:id_fanpage
        }
    };
};

export {status_delete_endpoint_page_action}