import {SERVER_API_NOTIFY} from "../../constans";
import {RSAA} from 'redux-api-middleware';
import queryString from 'query-string';
import {
    SEGMENT_ADMIN_LIST_FAILURE,
    SEGMENT_ADMIN_LIST_REQUEST,
    SEGMENT_ADMIN_LIST_SUCCESS,
} from "../../types";
import headers from "../../utils/headers";

const SegmentAdminListAction = (
    page = 1,
    app_id = null,
    collection_id = null,
    date_from = null,
    date_to = null,
    sort = "-created_at",
    limit = 10) => ({

    [RSAA]: {
        endpoint: () => {
            let parsed = {
                'limit': limit,
                'page': page,
                'sort': sort
            };

            if(app_id){
                parsed.app_id_from_partner = app_id
            }
            if(collection_id){
                parsed.collection_id_from_partner = collection_id
            }
            if(date_from){
                parsed.created_from = date_from
            }
            if(date_to){
                parsed.created_to = date_to
            }

            let query_param = queryString.stringify(parsed);
            return `${SERVER_API_NOTIFY}/segments-list?${query_param}`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            SEGMENT_ADMIN_LIST_REQUEST,
            {
                type: SEGMENT_ADMIN_LIST_SUCCESS,
                payload: async (action, state, res) => {
                    let data = [];
                    await res.json().then((json) => {
                        data = json.data
                    });

                    return {
                        "code": 0,
                        "type": SEGMENT_ADMIN_LIST_SUCCESS,
                        "data": data,
                    };
                }
            },
            {
                type: SEGMENT_ADMIN_LIST_FAILURE,
                payload: (action, state, res) => {
                    return res.json()
                },
            }
        ]
    }

});

export {SegmentAdminListAction}