import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {RSAA} from 'redux-api-middleware';
import {GET_ENDPOINT_OF_PAGE_FUNBOT} from "../../../types/index";
import queryString from 'query-string';
import headers from "../../../utils/headers";

const get_endpoint_of_page_action = (limit = 100, page = 1, id_fanpage = 0) => ({

    [RSAA]: {
        endpoint: () => {
            let parsed = {
                'limit': limit,
                'page': page,
                'sort':'-created_at'
            }
            let query_param = queryString.stringify(parsed);
            return `${SERVER_API_PLATFORMJK}/api/pages/${id_fanpage}/endpoints?${query_param}`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            'REQUEST_ENDPOINT_OF_PAGE_FUNBOT',
            {
                type: GET_ENDPOINT_OF_PAGE_FUNBOT,
                payload:async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            data = json.data
                        })
                        return {
                            "code": 0,
                            "type": "getEndPointsOfPage",
                            "data": data
                        }
                    }

                }
            },
            'FAILURE_ENDPOINT_OF_PAGE_FUNBOT'
        ]
    }

})

export {get_endpoint_of_page_action}