import {FACEBOOK_RATING_SUMMARY_SUCCESS} from "../../../types";


/**
 * @param {*} state
 * @param {*} action
 */
const RatingSummaryReducer = (state = {}, action) => {

    switch (action.type) {
        case FACEBOOK_RATING_SUMMARY_SUCCESS:
            return {...state, action};
        default:
            return state;
    }
};

export default RatingSummaryReducer;