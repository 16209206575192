import {SERVER_API_PLATFORMJK} from '../../constans';
import {RSAA} from 'redux-api-middleware';
import {GET_ALL_USER} from "./../../types/index";
import headers from "../../utils/headers";

/**
 * GET_FANPAGE
 */
const get_all_user = () => ({

    [RSAA]: {
        endpoint: `${SERVER_API_PLATFORMJK}/api/users`,
        method: 'GET',
        headers: (headers()),
        types: [
            'REQUEST_USERS_FUNBOT',
            {
                type: GET_ALL_USER,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        return res.json()
                    }

                }
            },
            'FAILURE_USERS_FUNBOT'
        ]
    }

})

export {get_all_user}