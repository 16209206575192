import {
    MSG_CREATE_SUCCESS,
    MSG_LIST_SUCCESS,
} from "../../../types";

/**
 * @param {*} state
 * @param {*} action
 */
const MessageReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case MSG_LIST_SUCCESS:
            let msgs = action.payload.data.data;

            msgs.map((msg) => {
                let id = msg['_id'];
                state[id] = msg;
                return true;
            });

            return {...state, action};

        case MSG_CREATE_SUCCESS:
            let id = action.payload.data['id'];
            state[id] = action.payload.data;
            return {...state, action};
        default:
            return state;
    }
};

export {MessageReducer}