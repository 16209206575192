import {
	LOGIN_BY_EMAIL, REGISTER_BY_EMAIL, REGISTER_BY_GOOGLE, LOGOUT,
	CLEAR_STORE, REFRESH_TOKEN, ROLES, FAILURE_REGISTER_BY_EMAIL, FAILURE_LOGIN_BY_EMAIL,
	GET_ME_INBOX_SUCCESS, GET_ME_INBOX_FAILURE, LOGIN_BY_GOOGLE_URL, LOGIN_BY_GOOGLE, LOGIN_BY_GOOGLE_SUCCESS
} from '../../types';

/**
 * user_login_reducer
 * @param {*} state 
 * @param {*} action 
 */
const user_login_reducer = (state = {}, action) => {
	switch (action.type) {
		case REGISTER_BY_EMAIL:
			return {
				...state,
				data: action.payload,
                user_name: action.payload.user
			};
        case FAILURE_LOGIN_BY_EMAIL:
        case FAILURE_REGISTER_BY_EMAIL:
        case ROLES:
        case REFRESH_TOKEN:
        case LOGIN_BY_EMAIL:
		case REGISTER_BY_GOOGLE:
		case LOGOUT:
		case GET_ME_INBOX_SUCCESS:
		case GET_ME_INBOX_FAILURE:
		case LOGIN_BY_GOOGLE_URL:
			return {
				...state,
				data: action.payload
			};
		case LOGIN_BY_GOOGLE:
		case LOGIN_BY_GOOGLE_SUCCESS:

		case CLEAR_STORE:
			return {
				...state,
				data: action.payload
			};
		default:
			return state;
	}
}

export {
	user_login_reducer
}