import {
    SEND_GIFT_CODE_SUCCESS,
    SEND_GIFT_CODE_FAILURE,
} from '../types';

/**
 * @param {*} state
 * @param {*} action
 */
const SendGiftCodeReducer = (state = {}, action) => {

    switch (action.type) {
        case SEND_GIFT_CODE_SUCCESS:
        case SEND_GIFT_CODE_FAILURE:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export {
    SendGiftCodeReducer
}