/**
 * is_access_token_expired_action
 */
const is_access_token_expired_action = (expires_in) => {
    if (expires_in) {
        return 1000 * expires_in - (new Date()).getTime() < 5000
    }
    return true
};

export { is_access_token_expired_action }