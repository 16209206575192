/**
 * @param {*} state
 * @param {*} action
 */
import {SEGMENT_GET_ONE_SUCCESS} from "../../types";

const SegmentGetOneReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case SEGMENT_GET_ONE_SUCCESS:
            let key = action.type + '_GET_FIRST_RESULT';
            state[key] = action.payload.data;

            return {...state, action};
        default:
            return state;
    }
};

export {SegmentGetOneReducer}