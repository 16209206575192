import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {RSAA} from 'redux-api-middleware';
import {GET_KEYWORD_FUNBOT, FAILURE_KEYWORD_FUNBOT} from "../../../types/index";
import queryString from 'query-string';
import headers from "../../../utils/headers";

/**
 * GET_KEYWORD
 */
const get_keyword_action = (limit = 100, page = 1) => ({

    [RSAA]: {
        endpoint: () => {
            let parsed = {}
            parsed = {
                'limit': limit,
                'page': page
            }
            let query_param = queryString.stringify(parsed);
            return `${SERVER_API_PLATFORMJK}/api/keywords?${query_param}`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            'REQUEST_KEYWORD_FUNBOT',
            {
                type: GET_KEYWORD_FUNBOT,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            data = json.data
                        })
                        return {
                            "code": 0,
                            "type": "get_keywords",
                            "data": data
                        }
                    }
                }
            },
            {
                type: FAILURE_KEYWORD_FUNBOT,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    if (contentType && ~contentType.indexOf('json')) {
                        return res.json()
                    }
                }
            },
        ]
    }

})

export {get_keyword_action}