import { GET_CONVERSATIONS, CLEAR_STORE } from '../types';
/**
 * @param {*} state
 * @param {*} action
 */
const conversations_reducer = (state = {}, action) => {
    switch (action.type) {
        case GET_CONVERSATIONS:
        case CLEAR_STORE:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
}

export {
    conversations_reducer
}