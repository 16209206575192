import {CLEAR_STORE, GET_APP_NOTIFY} from '../types';
/**
 * @param {*} state
 * @param {*} action 
 */
const notify_reducer = (state = {}, action) => {
	switch (action.type) {
        case GET_APP_NOTIFY:
        case CLEAR_STORE:
            return {
                ...state,
                data: action.payload
            };
		default:
			return state;
	}
};

export {
    notify_reducer
}