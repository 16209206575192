import {
    CHAT_LIST_SUCCESS, CHAT_UPDATE_SUCCESS,
} from "../../../types";

/**
 * @param {*} state
 * @param {*} action
 */
const ChatReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case CHAT_LIST_SUCCESS:
            let chats = action.payload.data.data;

            chats.map((chat) => {
                let id = chat['id'];
                state[id] = chat;
                return true;
            });

            return {...state, action};

        case CHAT_UPDATE_SUCCESS:
            let id = action.payload.data['id'];
            state[id] = action.payload.data;
            return {...state, action};
        default:
            return state;
    }
};

export {ChatReducer}