import {SERVER_API_FB_BOT} from '../../constans/index';
import {
    CREAT_GROUP_REQUEST,
    CREAT_GROUP_SUCCESS,
    CREAT_GROUP_FAILURE
} from '../../types/index';
import {RSAA} from 'redux-api-middleware';
import headers from "../../utils/headers";

const CreateGroupAction = (dataTarget) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_FB_BOT}/api/group`,
        method: 'POST',
        headers: (headers()),
        body: (() => {
            return JSON.stringify(dataTarget);
        }),
        types: [
            CREAT_GROUP_REQUEST,
            {
                type: CREAT_GROUP_SUCCESS,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            data = json.data
                        });
                        return {
                            "code": 0,
                            "type": CREAT_GROUP_SUCCESS,
                            "data": data
                        }
                    }

                }
            },
            {
                type: CREAT_GROUP_FAILURE,
                payload: async (action, state, res) => {

                    let message = "";
                    await res.json().then((json) => {
                        message = json.message;
                    });

                    return {
                        "code": 1,
                        "type": CREAT_GROUP_FAILURE,
                        "message": message,
                        "data": [],
                    }
                }
            }
        ]
    }

});

export {CreateGroupAction}