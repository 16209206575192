import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {DELETE_ENDPOINT_PAGE, FAILURE_DELETE_ENDPOINT_PAGE} from '../../../types/index';
import {RSAA} from 'redux-api-middleware';
import headers from "../../../utils/headers";

const delete_endpoint_page_action = (id_page,id_endpoint) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_PLATFORMJK}/api/pages/${id_page}/endpoints/${id_endpoint}`,
        method: 'DELETE',
        headers: (headers()),
        types: [
            'REQUEST_DELETE_ENDPOINT_PAGE',
            {
                type: DELETE_ENDPOINT_PAGE,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        return {
                            "code": 0,
                            "type": "delete_endpoint"
                        }
                    }
                },
            },
            {
                type: FAILURE_DELETE_ENDPOINT_PAGE,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        return {
                            "code": 1,
                            "type": "delete_endpoint",
                            "data": res.json()
                        }
                    }
                },
            },
        ]
    }

});

export {delete_endpoint_page_action}