import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {UPDATE_FANPAGE_FUNBOT} from '../../../types/index';
import {RSAA} from 'redux-api-middleware';
import headers from "../../../utils/headers";

const update_page_facebook_action = (data_target, id) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_PLATFORMJK}/api/pages/${id}`,
        method: 'PUT',
        headers: (headers()),
        body: (() => {
            return JSON.stringify(data_target)
        }),
        types: [
            'REQUEST_UPDATE_FANPAGE_FUNBOT',
            {
                type: UPDATE_FANPAGE_FUNBOT,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        //return res.json()
                        return {
                            "code": 0,
                            "type": "update_page"
                        }
                    }
                },
            },
            'FAILURE_UPDATE_FANPAGE_FUNBOT'
        ]
    }

});

export {update_page_facebook_action}