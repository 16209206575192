import {SERVER_API_PLATFORMJK, USER_INFO_FUNBOT_V2} from '../../constans';
import {ROLES} from '../../types';
import RSAA from "redux-api-middleware/es/RSAA";
import userInfoSave from "../../utils/userInfoSave";

const GetMeAction = (token) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_PLATFORMJK}/api/me`,
        method: 'GET',
        headers: {"Content-Type": "application/json", "Authorization": token},
        types: [
            'REQUEST-ROLES',
            {
                type: ROLES,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            userInfoSave(json.data, USER_INFO_FUNBOT_V2);

                            //TODO: remove these setItem, use userInfoGet instead
                            localStorage.setItem('roles', JSON.stringify(json.data.roles));
                            localStorage.setItem('txtUserId', json.data['uuid']);
                            localStorage.setItem('txtUserName', json.data.name);

                        });
                        if (res.status === 200)
                            return {
                                "code": 0,
                                "type": "roles"
                            }
                    }
                }
            },
            'FAILURE-ROLES'
        ]
    }
});

export {GetMeAction}