import axios from 'axios';
import {SERVER_API_AUTH2} from '../../constans';
import {LOGOUT} from '../../types';
import {issetFiled, redirect_page} from '../../utils';
import {is_access_token_expired_action} from "..";
import jwt_decode from "jwt-decode";

/**LOGOUT
 */
const logout_action = () => {
    const token_type = issetFiled('token_type') ? JSON.parse(localStorage.getItem('token_type')) : null;
    const access_token = issetFiled('access_token') ? JSON.parse(localStorage.getItem('access_token')) : null;

    const token = token_type + ' ' + access_token;

    // clear all data
    localStorage.clear();
    sessionStorage.clear();
    redirect_page('/');
    // if (access_token && is_access_token_expired_action(jwt_decode(access_token).exp)) {
    //     return axios({
    //         method: 'get',
    //         url: `${SERVER_API_AUTH2}/api/auth/logout`,
    //         headers: {"Authorization": token, "Content-Type": "application/json"},
    //     })
    //         .then(res => {
    //             //const data_result = res.data;
    //             redirect_page('/');
    //             return {
    //                 type: LOGOUT,
    //                 payload: {}
    //             };
    //         })
    //         .catch(err => {
    //             redirect_page('/');
    //             return {
    //                 type: LOGOUT,
    //                 payload: {}
    //             };
    //         });
    // } else
    //     redirect_page('/');
};

export {logout_action}