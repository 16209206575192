import {SERVER_API_FB_BOT} from '../../constans';
import {
    GET_ME_INBOX_REQUEST,
    GET_ME_INBOX_SUCCESS,
    GET_ME_INBOX_FAILURE,
} from '../../types';
import RSAA from "redux-api-middleware/es/RSAA";
import userInfoSave from "../../utils/userInfoSave";
import moment from "moment";

const GetMeInboxAction = (token) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_FB_BOT}/api/me`,
        method: 'GET',
        headers: {"Content-Type": "application/json", "Authorization": token},
        types: [
            'GET_ME_INBOX_REQUEST',
            {
                type: GET_ME_INBOX_SUCCESS,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {

                            localStorage.setItem('infoUserInbox', JSON.stringify(json.data));

                            let currentDate = moment().unix();
                            localStorage.setItem('timeOnline', currentDate);
                        });
                        if (res.status === 200)
                            return {
                                "code": 0,
                                "type": GET_ME_INBOX_SUCCESS
                            }
                    }
                }
            },
            GET_ME_INBOX_FAILURE
        ]
    }
});

export {GetMeInboxAction}