import { CLEAR_STORE } from '../types';

/**
 * CLEAR_STORE
 */
const clear_store_action = () => {
	return {
		type: CLEAR_STORE,
		payload: false
	};
};

export { clear_store_action };