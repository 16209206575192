import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {headers} from '../../../utils/index';
import {RSAA} from 'redux-api-middleware';
import {GET_FANPAGE_FUNBOT} from "../../../types/index";
import queryString from 'query-string';

/**
 * GET_FANPAGE
 */
const get_fan_page_action = (limit = 100, page = 1, page_name = null, sort = "-created_at") => ({

    [RSAA]: {
        endpoint: () => {
            let parsed;
            page_name ? parsed = {
                'limit': limit,
                'page': page,
                'page_name': page_name,
                'sort': sort
            } : parsed = {
                'limit': limit,
                'page': page,
                'sort': sort
            };
            let query_param = queryString.stringify(parsed);
            return `${SERVER_API_PLATFORMJK}/api/v2/pages?${query_param}`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            'REQUEST_FANPAGE_FUNBOT',
            {
                type: GET_FANPAGE_FUNBOT,
                payload:async (action, state, res) => {
                                const contentType = res.headers.get('Content-Type');
                                let data = [];
                                if (contentType && ~contentType.indexOf('json')) {
                                    await res.json().then((json) => {
                                        data = json.data
                                    });

                                    return {
                                        "code": 0,
                                        "type": "getFanpages",
                                        "data": data
                                    }
                                }

                            }

            },
            'FAILURE_FANPAGE_FUNBOT'
        ]
    }

});

export {get_fan_page_action}