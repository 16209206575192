import {SERVER_API_PLATFORMJK} from '../../constans';
import {RSAA} from 'redux-api-middleware';
import {GET_ROLES} from "./../../types/index";
import headers from "../../utils/headers";

const get_roles_action = () => ({

     [RSAA]: {
        endpoint: `${SERVER_API_PLATFORMJK}/api/roles`,
        method: 'GET',
        headers: (headers()),
        types: [
            'REQUEST_ROLES',
            {
                type: GET_ROLES,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            data = json.data
                        })
                        return {
                            "code": 0,
                            "type": "get_roles",
                            "data": data
                        }
                    }
                }
            },
            'FAILURE_ROLES'
        ]
     }

})

export {get_roles_action}