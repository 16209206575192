import {SERVER_API_FB_BOT} from '../../constans/index';
import {
    LIST_USER_OF_PAGE_REQUEST,
    LIST_USER_OF_PAGE_SUCCESS,
    LIST_USER_OF_PAGE_FAILURE,
} from '../../types/index';
import {RSAA} from 'redux-api-middleware';
import headers from "../../utils/headers";
import queryString from "query-string";

const ListUsersOfPagesAction = (pageId, username = null, limit = 10, page = 1, sort = "-last_message_time", lastSender = null, expireReply = null, isDone = null, idUser = null, filterTag = null) => ({

    [RSAA]: {
        endpoint: () => {
            let parsed = {
                'limit': limit,
                'page': page,
                'sort': sort
            };
            if (username)
                parsed.username = username;
            if (lastSender !== null)
                parsed.last_sender = lastSender;
            if (expireReply !== null)
                parsed.expire_reply = expireReply;
            if (isDone !== null)
                parsed.is_done = isDone;
            if (idUser !== null)
                parsed.user_id = idUser;
            if (filterTag !== null)
                parsed.tag = filterTag;
            let queryParam = queryString.stringify(parsed);
            return `${SERVER_API_FB_BOT}/api/pages/${pageId}/users?${queryParam}`;
        },
        method: 'GET',
        headers: (headers()),
        types: [
            LIST_USER_OF_PAGE_REQUEST,
            {
                type: LIST_USER_OF_PAGE_SUCCESS,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            data = json.data
                        });
                        return {
                            "code": 0,
                            "type": LIST_USER_OF_PAGE_SUCCESS,
                            "data": data
                        }
                    }

                }
            },
            {
                type: LIST_USER_OF_PAGE_FAILURE,
                payload: async (action, state, res) => {

                    let message = "";
                    await res.json().then((json) => {
                        message = json.message;
                    });

                    return {
                        "code": 1,
                        "type": LIST_USER_OF_PAGE_FAILURE,
                        "message": message,
                        "data": [],
                    }
                }
            }
        ]
    }

});

export {ListUsersOfPagesAction}