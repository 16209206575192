import {
    GET_FANPAGE_FUNBOT,
    DETAIL_FANPAGE_FUNBOT,
    CLEAR_STORE,
    CREATE_FANPAGE_FUNBOT,
    UPDATE_FANPAGE_FUNBOT,
    DELETE_FANPAGE_FUNBOT,
    FAILURE_CREATE_FANPAGE_FUNBOT,
    FAILURE_DELETE_FANPAGE_FUNBOT,
    GET_APP_FUNBOT,
    GET_KEYWORD_FUNBOT,
    FAILURE_CREATE_KEYWORD_FUNBOT,
    CREATE_KEYWORD_FUNBOT,
    FAILURE_KEYWORD_FUNBOT,
    ADD_KEYWORDS_FANPAGE_FUNBOT,
    FAILURE_ADD_KEYWORDS_FANPAGE_FUNBOT,
    GET_ENDPOINT_FUNBOT,
    DETAIL_ENDPOINT_FUNBOT,
    FAILURE_CREATE_ENDPOINT,
    CREATE_ENDPOINT,
    FAILURE_UPDATE_ENDPOINT,
    UPDATE_ENDPOINT,
    GET_ENDPOINT_OF_PAGE_FUNBOT,
    IS_EDIT_ENDPOINT,
    DELETE_ENDPOINT_PAGE,
    FAILURE_DELETE_ENDPOINT_PAGE,
    ADD_ENDPOINTS_FANPAGE_FUNBOT,
    FAILURE_ADD_ENDPOINTS_FANPAGE_FUNBOT,
    STATUS_DELETE_ENDPOINT_PAGE,
    FANPAGE_CREATE_V2_SUCCESS,
    FANPAGE_CREATE_V2_FAILURE,
    GET_NAME_FANPAGE_FUNBOT,
    FAILURE_NAME_FANPAGE_FUNBOT,
    GET_LIST_POST_FANPAGE_V2_SUCCESS,
    GET_LIST_POST_FANPAGE_V2_FAILURE, FANPAGE_POST_CREATE_SUCCESS, TRIGGER_CREATE_SUCCESS, TRIGGER_CREATE_FAILURE,
} from '../types';

/**
 * @param {*} state
 * @param {*} action
 */
const facebook_reducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_KEYWORDS_FANPAGE_FUNBOT:
        case FAILURE_ADD_KEYWORDS_FANPAGE_FUNBOT:
        case FAILURE_KEYWORD_FUNBOT:
        case CREATE_KEYWORD_FUNBOT:
        case FAILURE_CREATE_KEYWORD_FUNBOT:
        case GET_KEYWORD_FUNBOT:
        case GET_APP_FUNBOT:
        case FAILURE_DELETE_FANPAGE_FUNBOT:
        case FAILURE_CREATE_FANPAGE_FUNBOT:
        case DELETE_FANPAGE_FUNBOT:
        case UPDATE_FANPAGE_FUNBOT:
        case CREATE_FANPAGE_FUNBOT:
        case GET_FANPAGE_FUNBOT:
        case DETAIL_FANPAGE_FUNBOT:
        case GET_ENDPOINT_FUNBOT:
        case DETAIL_ENDPOINT_FUNBOT:
        case FAILURE_CREATE_ENDPOINT:
        case CREATE_ENDPOINT:
        case UPDATE_ENDPOINT:
        case FAILURE_UPDATE_ENDPOINT:
        case GET_ENDPOINT_OF_PAGE_FUNBOT:
        case IS_EDIT_ENDPOINT:
        case DELETE_ENDPOINT_PAGE:
        case FAILURE_DELETE_ENDPOINT_PAGE:
        case ADD_ENDPOINTS_FANPAGE_FUNBOT:
        case FAILURE_ADD_ENDPOINTS_FANPAGE_FUNBOT:
        case STATUS_DELETE_ENDPOINT_PAGE:
        case FANPAGE_CREATE_V2_SUCCESS:
        case FANPAGE_CREATE_V2_FAILURE:
        case GET_NAME_FANPAGE_FUNBOT:
        case FAILURE_NAME_FANPAGE_FUNBOT:
        case GET_LIST_POST_FANPAGE_V2_SUCCESS:
        case GET_LIST_POST_FANPAGE_V2_FAILURE:
        case FANPAGE_POST_CREATE_SUCCESS:
        case TRIGGER_CREATE_SUCCESS:
        case TRIGGER_CREATE_FAILURE:
        case CLEAR_STORE:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export {
    facebook_reducer
}