// import {SERVER_API_PLATFORMJK} from '../../constans';
// import {isset_filed} from '../../utils';
// import {RSAA} from 'redux-api-middleware';
// import {GET_ALL_USER} from "./../../types/index";

const update_user_action = () => ({

    // [RSAA]: {
    //     endpoint: `${SERVER_API_PLATFORMJK}/api/users`,
    //     method: 'GET',
    //     headers: (() => {
    //         const token_type = isset_filed('token_type') ? JSON.parse(localStorage.getItem('token_type')) : null;
    //         const access_token = isset_filed('access_token') ? JSON.parse(localStorage.getItem('access_token')) : null;
    //         const token = token_type + ' ' + access_token;
    //         return {"Content-Type": "application/json", "Accept": "application/json", "Authorization": token};
    //     }),
    //     types: [
    //         'REQUEST_USERS_FUNBOT',
    //         {
    //             type: GET_ALL_USER,
    //             payload: (action, state, res) => {
    //                 const contentType = res.headers.get('Content-Type');
    //
    //                 if (contentType && ~contentType.indexOf('json')) {
    //                     return res.json()
    //                 }
    //
    //             }
    //         },
    //         'FAILURE_USERS_FUNBOT'
    //     ]
    // }

})

export {update_user_action}