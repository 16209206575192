import {
    HEAR_DETAIL_SUCCESS,
    HEAR_DETAIL_FAILURE,
    HEAR_UPDATE_SUCCESS,
    HEAR_UPDATE_FAILURE,
    HEAR_CREATE_SUCCESS,
    HEAR_DELETE_SUCCESS,
    HEAR_DELETE_FAILURE,
    FANPAGE_GET_HEARS_SUCCESS,
} from '../types';

/**
 * @param {*} state
 * @param {*} action
 */
const HearReducer = (state = {}, action) => {
    switch (action.type) {
        case HEAR_UPDATE_SUCCESS:
        case HEAR_UPDATE_FAILURE:
        case HEAR_DETAIL_SUCCESS:
        case HEAR_DETAIL_FAILURE:
        case HEAR_CREATE_SUCCESS:
        case HEAR_DELETE_SUCCESS:
        case HEAR_DELETE_FAILURE:
        case FANPAGE_GET_HEARS_SUCCESS:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export {
    HearReducer
}