import { GET_USER_INFO,GET_USER_INFO_FUNBOT, CLEAR_STORE } from '../types';
//import { normalize } from 'normalizr';
/**
 * user Info
 * @param {*} state 
 * @param {*} action 
 */
const user_info_reducer = (state = {}, action) => {
	switch (action.type) {
        case GET_USER_INFO_FUNBOT:
		case GET_USER_INFO:
        case CLEAR_STORE:
            return {
                ...state,
                data: action.payload
            };
		default:
			return state;
	}
}

export {
	user_info_reducer
}