import {SERVER_API_PLATFORMJK} from '../../constans/index';
import {headers} from '../../utils/index';
import {RSAA} from 'redux-api-middleware';
import {
    GET_CONVERSATIONS,
} from "../../types/index";
import queryString from 'query-string';

const get_conversations_action = (limit = 100, page = 1, admin_id = null, sort = "-created_at",is_admin_responded = null,client_id = null) => ({

    [RSAA]: {
        endpoint: () => {
            let parsed = {
                'limit': limit,
                'page': page,
                'sort': sort
            }

            if(is_admin_responded)
                parsed.is_admin_responded = is_admin_responded
            if(client_id)
                parsed.client_id = client_id
            if(admin_id)
                parsed.admin_id = admin_id
            if(admin_id && is_admin_responded && client_id){
                parsed.is_admin_responded = is_admin_responded
                parsed.client_id = client_id
                parsed.admin_id = admin_id
            }

            let query_param = queryString.stringify(parsed);
            return `${SERVER_API_PLATFORMJK}/api/conversations?${query_param}`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            'REQUEST_CONVERSATIONS',
            {
                type: GET_CONVERSATIONS,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        return res.json()
                    }

                }
            },
            'FAILURE_CONVERSATIONS'
        ]
    }

})

export {get_conversations_action}