import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import ReactLoading from 'react-loading';

export const LoadingSpinner = (props) => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        <div>
            {
                (promiseInProgress === true) ? <div className="text-center loading_data">
                    <ReactLoading type={"bars"} color={"#000"} height={40} width={40}/>
                </div>:null
            }
        </div>
    )
};