import {
    POST_SEEDING_KEYWORD_LIST_SUCCESS,
    POST_SEEDING_KEYWORD_CREATE_SUCCESS,
    SEEDING_KEYWORD_DELETE_SUCCESS,
    SEEDING_KEYWORD_UPDATE_SUCCESS,
} from "../../../types";


/**
 * @param {*} state
 * @param {*} action
 */
const PostKeywordsReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    let parent_id = action.payload.parent_id;
    if (parent_id === undefined || parent_id === null) return state;
    if (state[parent_id] === undefined) state[parent_id] = {};

    switch (action.type) {
        case POST_SEEDING_KEYWORD_LIST_SUCCESS:
            let keywords = action.payload.data.data;
            keywords.map((keyword) => {
                let id = keyword['id'];
                state[parent_id][id] = keyword;
                return true;
            });
            return {...state, action: action};
        case SEEDING_KEYWORD_DELETE_SUCCESS:
        case SEEDING_KEYWORD_UPDATE_SUCCESS:
        case POST_SEEDING_KEYWORD_CREATE_SUCCESS:
            return {...state, action: action};
        default:
            return state;
    }
};

export {PostKeywordsReducer}