import {
    SEGMENT_MSG_DL_LIST_SUCCESS,
} from "../../../types";

/**
 * @param {*} state
 * @param {*} action
 */
const SegmentMsgDlReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case SEGMENT_MSG_DL_LIST_SUCCESS:
            let messages = action.payload.data;

            let index = 0;
            // reset state to make sure its empty
            state = [];
            messages.map((message) => {
                state[index] = message;
                index++;
                return true;
            });

            return {...state, action};
        default:
            return state;
    }
};

export {SegmentMsgDlReducer}