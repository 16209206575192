import {FANPAGE_POST_FROM_FACEBOOK_SUCCESS} from "../../../types";


/**
 * @param {*} state
 * @param {*} action
 */
const FanpagePostsFromFaceBookReducer = (state = {}, action) => {

    switch (action.type) {
        case FANPAGE_POST_FROM_FACEBOOK_SUCCESS:
            return {...state, action: action};
        default:
            return state;
    }
};

export {
    FanpagePostsFromFaceBookReducer
}