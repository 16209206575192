/**
 |--------------------------------------------------
 | CLIENT CONFIG
 |--------------------------------------------------
 */


import {
    SERVER_API_AUTH2,
    BASE_URL,
    SERVER_API_PLATFORMJK,
    SERVER_API_NOTIFY,
    SERVER_EMAIL_NOTIFY,
    SERVER_GG_STORE_REPLY,
    SERVER_API_FB_BOT
} from './api_config';

export {
    SERVER_API_AUTH2,
    BASE_URL,
    SERVER_API_PLATFORMJK,
    SERVER_API_NOTIFY,
    SERVER_EMAIL_NOTIFY,
    SERVER_GG_STORE_REPLY,
    SERVER_API_FB_BOT
};

export const EMAIL_FORMAT = /^[a-z]+[a-z0-9._]+@[a-z-]+\.[a-z.]{2,8}$/;
export const EMAIL_FUNTAP = /@(funtap.vn|techlab.ai|9pay.vn)\s*$/;
export const PHONE_FORMAT = /^(0[126389]|09)[0-9]{8}$/;
export const TOKEN_RECEIVED = '@@jwt/TOKEN_RECEIVED';

export const USER_INFO_AUTH2 = 'auth2';
export const USER_INFO_FUNBOT_V2 = 'funbot_v2';
