/**
 * @param {*} state
 * @param {*} action
 */
import {APP_LIST_SEARCH_SUCCESS, APP_LIST_SUCCESS, APP_WITHOUT_COMPANY_LIST_SUCCESS} from "../../types";

const AppsReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case APP_LIST_SUCCESS:
        case APP_WITHOUT_COMPANY_LIST_SUCCESS:
            let key = action.type + '_SEARCH_RESULT';
            state[key] = action.payload.data;

            return {...state, action};
        case APP_LIST_SEARCH_SUCCESS:
            let sKey = action.type + '_LIST_SEARCH_RESULT';
            state[sKey] = action.payload.data;

            return {...state, action};
        default:
            return state;
    }
};

export {AppsReducer}