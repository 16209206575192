import {
    APP_LIST_SUCCESS,
    COMPANY_LIST_SUCCESS,
    COMPANY_LIST_SEARCH_SUCCESS,
    DOMAIN_NOTIFY_EMAIL_LIST_SUCCESS,
    DOMAIN_NOTIFY_LIST_SUCCESS,
    FANPAGE_ANSWER_LIST_SUCCESS,
    FANPAGE_GET_HEARS_SUCCESS,
    FANPAGE_LIST_SUCCESS,
    FANPAGE_POST_LIST_SUCCESS,
    FANPAGE_REEL_LIST_SUCCESS,
    MY_COMPANIES_DOMAIN_LIST_SUCCESS,
    MY_DOMAIN_LIST_SUCCESS,
    APPROVAL_TELEGRAM_GROUP_LIST_SUCCESS,
    EMAIL_BLACKLIST_LIST_SUCCESS,
    EMAIL_BLACKLIST_LOG_SUCCESS,
    SHORT_LINK_LIST_SUCCESS,
    SHORT_LINK_USER_LIST_SUCCESS,
    DOMAIN_LIST_SUCCESS,
    POST_ANSWER_LIST_SUCCESS,
    POST_SEEDING_KEYWORD_LIST_SUCCESS,
    SEGMENT_ADMIN_LIST_SUCCESS,
    SEGMENT_LIST_SUCCESS,
    TEMPLATE_LIST_SUCCESS,
    REPORT_API_USE_SUCCESS,
    REPORT_API_DO_NOT_USE_SUCCESS,
    TEAM_LIST_SUCCESS,
    LOGS_SUCCESS,
    TEAM_MEMBER_LIST_SUCCESS,
    USER_LIST_SUCCESS,
    PUSH_NOTIFY_SUCCESS,
    USER_UPDATE_SUCCESS,
    MSG_LIST_SUCCESS,
    GROUP_LIST_SUCCESS,
    GAME_SUCCESS,
    GAME_FAILURE,
    COMPANY_APP_LIST_SUCCESS,
    APP_WITHOUT_COMPANY_LIST_SUCCESS,
    MSG_SEGMENT_LIST_SUCCESS,
    CHAT_LIST_SUCCESS, TEMPLATE_EMAIL_LIST_SUCCESS
} from "../types";


/**
 * @param {*} state
 * @param {*} action
 */
const PaginateReducer = (state = {}, action) => {
    if (action.payload === undefined) return {...state};

    switch (action.type) {
        case TEAM_LIST_SUCCESS:
        case TEAM_MEMBER_LIST_SUCCESS:
        case USER_LIST_SUCCESS:
        case USER_UPDATE_SUCCESS:
        case DOMAIN_NOTIFY_EMAIL_LIST_SUCCESS:
        case FANPAGE_POST_LIST_SUCCESS:
        case FANPAGE_REEL_LIST_SUCCESS:
        case FANPAGE_ANSWER_LIST_SUCCESS:
        case POST_ANSWER_LIST_SUCCESS:
        case FANPAGE_GET_HEARS_SUCCESS:
        case FANPAGE_LIST_SUCCESS:
        case SEGMENT_LIST_SUCCESS:
        case SEGMENT_ADMIN_LIST_SUCCESS:
        case APP_LIST_SUCCESS:
        case APP_WITHOUT_COMPANY_LIST_SUCCESS:
        case COMPANY_LIST_SUCCESS:
        case COMPANY_LIST_SEARCH_SUCCESS:
        case COMPANY_APP_LIST_SUCCESS:
        case MY_DOMAIN_LIST_SUCCESS:
        case MY_COMPANIES_DOMAIN_LIST_SUCCESS:
        case APPROVAL_TELEGRAM_GROUP_LIST_SUCCESS:
        case EMAIL_BLACKLIST_LIST_SUCCESS:
        case EMAIL_BLACKLIST_LOG_SUCCESS:
        case SHORT_LINK_LIST_SUCCESS:
        case SHORT_LINK_USER_LIST_SUCCESS:
        case DOMAIN_LIST_SUCCESS:
        case TEMPLATE_LIST_SUCCESS:
        case DOMAIN_NOTIFY_LIST_SUCCESS:
        case POST_SEEDING_KEYWORD_LIST_SUCCESS:
        case REPORT_API_USE_SUCCESS:
        case REPORT_API_DO_NOT_USE_SUCCESS:
        case LOGS_SUCCESS:
        case PUSH_NOTIFY_SUCCESS:
        case GAME_SUCCESS:
        case GAME_FAILURE:
        case MSG_LIST_SUCCESS:
        case GROUP_LIST_SUCCESS:
        case MSG_SEGMENT_LIST_SUCCESS:
        case TEMPLATE_EMAIL_LIST_SUCCESS:
        case CHAT_LIST_SUCCESS:
            let key = action.type;
            key += '_' + action.payload.id;
            if (state[key] === undefined) state[key] = {};

            state[key] = action.payload.data;
            return {...state, action};
        default:
            return {...state};
    }
};

export {PaginateReducer}
