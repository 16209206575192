/**
 |--------------------------------------------------
 | ALL REDUCERS
 |--------------------------------------------------
 */
import {combineReducers} from 'redux';
import {user_login_reducer} from './login_register/LoginRegisterReducer';
import {user_info_reducer} from './GetUserInfoReducer';
import {facebook_reducer} from './FacebookReducer';
import UsersReducer from './UsersReducer';
import {roles_reducer} from './RolesReducer';
import {conversations_reducer} from './ConversationsReducer';
import {notify_reducer} from './NotifyReducer';
import {modal_reducer} from './ModalReducer';
import {autocomplete_reducer} from './AutocompleteReducer';
import {FanpageGetStartedStatusReducer} from "./Facebook/Fanpage/FanpageGetStartedStatusReducer";
import {HearReducer} from "./HearReducer";
import {HearCreateReducer} from "./HearCreateReducer";
import {HearFanpageStartedReducer} from "./HearFanpageStartedReducer";
import {FanpageHearsModalReducer} from "./Facebook/Fanpage/FanpageHearsModalReducer";
import {HearsReducer} from "./HearsReducer";
import {FanpagesReducer} from "./Facebook/Fanpage/FanpagesReducer";
import {TeamsReducer} from "./Admin/Team/TeamsReducer";
import {TeamMemberReducer} from "./Admin/Team/TeamMemberReducer";
import {DomainsReducer} from "./EmailNotify/DomainsReducer";
import {DomainNotifyEmailsReducer} from "./EmailNotify/DomainNotifyEmailsReducer";
import {DomainNotifiesReducer} from "./EmailNotify/DomainNotifiesReducer";
import {ApprovalTelegramReducer} from "./EmailNotify/ApprovalTelegram/ApprovalTelegramReducer";
import {TemplatesReducer} from "./EmailNotify/TemplatesReducer";
import {EmailBlacklistReducer} from "./EmailNotify/EmailBlacklist/EmailBlacklistReducer";
import {FanpagePostsReducer} from "./Facebook/Post/FanpagePostsReducer";
import {FanpageReelsReducer} from "./Facebook/Post/FanpageReelsReducer";
import {PostAnswersReducer} from "./Facebook/Answer/PostAnswersReducer";
import {FanpageAnswersReducer} from "./Facebook/Answer/FanpageAnswersReducer";
import {PaginateReducer} from "./PaginateReducer";
import {ApiErrorMessageReducer} from "./ApiErrorMessageReducer";
import {SegmentsReducer} from "./Notify/SegmentsReducer";
import {SegmentsAdminReducer} from "./Notify/SegmentsAdminReducer";
import {AppByKeysReducer} from "./Notify/AppByKeysReducer";
import {AppsReducer} from "./Notify/AppsReducer";
import {CompanyListReducer} from "./Notify/CompanyListReducer";
import {CompanyAppReducer} from "./Notify/CompanyAppReducer";
import {FanpagePostsFromFaceBookReducer} from "./Facebook/Post/FanpagePostFromFacebookReducer";
import {FanpageReelsFromFaceBookReducer} from "./Facebook/Post/FanpageReelFromFacebookReducer";
import {SegmentGetOneReducer} from "./Notify/SegmentGetOneReducer";
import RatingDetailReducer from "./Facebook/RaTing/RatingDetailReducer";
import RatingSummaryReducer from "./Facebook/RaTing/RatingSummaryReducer";
import {PostKeywordsReducer} from "./Facebook/SeedingKeyword/PostKeywordsReducer";
import MenuReducer from "./Facebook/Menu/MenuReducer";
import {ActivityLogsReducer} from "./ActivityLogs/ActivityLogsReducer";
import {PostsSummaryReducer} from "./Facebook/Post/PostSummaryReducer";
import {PushNotifyReducer} from "./PushNotifyReducer";
import {GameReducer} from "./GameReducer";
import {InboxReducer} from "./InboxReducer";
import {SendGiftCodeReducer} from "./SendGiftCodeReducer";
import {FanpagePostsReviewReducer} from "./Facebook/Post/FanpagePostsReviewReducer";
import {PostReviewExportReducer} from "./Facebook/Post/PostReivewExportReducer";
import {SendGiftCodeTrackingReducer} from "./Facebook/Post/SendGiftCodeTrackingReducer";
import {SendMessagesReducer} from "./Facebook/Fanpage/SendMessagesReducer";
import {SendMessagesStatusReducer} from "./Facebook/Fanpage/SendMessagesStatusReducer";
import {MessageReducer} from "./Ems/Message/MessageReducer";
import {GroupReducer} from "./Ems/Group/GroupReducer";
import {RankingReducer} from "./Facebook/Fanpage/RankingReducer";
import {SegmentMsgReducer} from "./Ems/Segment/SegmentMsgReducer";
import {ChatReducer} from "./Ems/Chat/ChatReducer";
import {TeleUserReducer} from "./Ems/TeleUser/TeleUserReducer";
import {HearReportReducer} from "../reducers/Facebook/Fanpage/HearReportReducer";
import {SegmentMsgDlReducer} from "./Ems/Segment/SegmentMsgDlReducer";

const reducer = combineReducers({
    user_login_reducer,
    user_info_reducer,
    facebook_reducer,
    UsersReducer,
    roles_reducer,
    conversations_reducer,
    notify_reducer,
    modal_reducer,
    autocomplete_reducer,
    Users: UsersReducer,
    FanpageGetStartedStatusReducer,
    HearReducer,
    HearCreateReducer,
    HearFanpageStartedReducer,
    FanpageHearsModalReducer,
    HearsReducer,
    FanpagesReducer,
    TeamsReducer,
    TeamMemberReducer,
    DomainsReducer,
    DomainNotifyEmailsReducer,
    DomainNotifiesReducer,
    EmailBlacklistReducer,
    ApprovalTelegramReducer,
    TemplatesReducer,
    FanpagePostsReducer,
    FanpageReelsReducer,
    PostAnswersReducer,
    PaginateReducer,
    FanpageAnswersReducer,
    ApiErrorMessageReducer,
    FanpagePostsFromFaceBookReducer,
    FanpageReelsFromFaceBookReducer,
    SegmentsReducer,
    SegmentsAdminReducer,
    AppByKeysReducer,
    AppsReducer,
    CompanyListReducer,
    CompanyAppReducer,
    SegmentGetOneReducer,
    RatingDetailReducer,
    RatingSummaryReducer,
    PostKeywordsReducer,
    MenuReducer,
    ActivityLogsReducer,
    PostsSummaryReducer,
    PushNotifyReducer,
    GameReducer,
    InboxReducer,
    SendGiftCodeReducer,
    SendMessagesReducer,
    SendMessagesStatusReducer,
    FanpagePostsReviewReducer,
    RankingReducer,
    PostReviewExportReducer,
    SendGiftCodeTrackingReducer,
    MessageReducer,
    GroupReducer,
    SegmentMsgReducer,
    ChatReducer,
    TeleUserReducer,
    HearReportReducer,
    SegmentMsgDlReducer,
});

export default reducer;
