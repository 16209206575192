import {
    GROUP_CREATE_SUCCESS,
    GROUP_LIST_SUCCESS, GROUP_UPDATE_SUCCESS,
} from "../../../types";

/**
 * @param {*} state
 * @param {*} action
 */
const GroupReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case GROUP_LIST_SUCCESS:
            let groups = action.payload.data.data;

            groups.map((group) => {
                let id = group['id'];
                state[id] = group;
                return true;
            });

            return {...state, action};

        case GROUP_CREATE_SUCCESS:
        case GROUP_UPDATE_SUCCESS:
            let id = action.payload.data['id'];
            state[id] = action.payload.data;
            return {...state, action};
        default:
            return state;
    }
};

export {GroupReducer}