import {
    HEAR_CREATE_SUCCESS,
    HEAR_CREATE_FAILURE,
    TRIGGER_CREATE_SUCCESS,
    TRIGGER_CREATE_FAILURE,
    FANPAGE_GET_HEARS_SUCCESS,
    TRIGGER_DETAIL_SUCCESS,
    TRIGGER_DETAIL_FAILURE,
    HEAR_DETAIL_SUCCESS,
    HEAR_DETAIL_FAILURE,
    HEAR_UPDATE_SUCCESS,
    HEAR_UPDATE_FAILURE,
    TRIGGER_UPDATE_SUCCESS,
    TRIGGER_UPDATE_FAILURE,
    TRIGGER_SUMMARY_SUCCESS,
    TRIGGER_SUMMARY_FAILURE,
    TRIGGER_SUMMARY_COUNTING_SUCCESS,
    TRIGGER_SUMMARY_COUNTING_FAILURE,
    TRIGGER_SUMMARY_STOP_SUCCESS,
    TRIGGER_SUMMARY_STOP_FAILURE,
    TRIGGER_SUMMARY_RESET_SUCCESS,
    TRIGGER_SUMMARY_RESET_FAILURE
} from '../types';

/**
 * @param {*} state
 * @param {*} action
 */
const PushNotifyReducer = (state = {}, action) => {
    switch (action.type) {
        case HEAR_CREATE_SUCCESS:
        case HEAR_CREATE_FAILURE:
        case TRIGGER_CREATE_SUCCESS:
        case TRIGGER_CREATE_FAILURE:
        case FANPAGE_GET_HEARS_SUCCESS:
        case TRIGGER_DETAIL_SUCCESS:
        case TRIGGER_DETAIL_FAILURE:
        case HEAR_DETAIL_SUCCESS:
        case HEAR_DETAIL_FAILURE:
        case HEAR_UPDATE_SUCCESS:
        case HEAR_UPDATE_FAILURE:
        case TRIGGER_UPDATE_SUCCESS:
        case TRIGGER_UPDATE_FAILURE:
        case TRIGGER_SUMMARY_SUCCESS:
        case TRIGGER_SUMMARY_FAILURE:
        case TRIGGER_SUMMARY_COUNTING_SUCCESS:
        case TRIGGER_SUMMARY_COUNTING_FAILURE:
        case TRIGGER_SUMMARY_STOP_SUCCESS:
        case TRIGGER_SUMMARY_STOP_FAILURE:
        case TRIGGER_SUMMARY_RESET_SUCCESS:
        case TRIGGER_SUMMARY_RESET_FAILURE:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export {
    PushNotifyReducer
}