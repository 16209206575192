import {GET_MODAL} from "../../types";

/**
 * CLEAR_STORE
 */
const modal_action = () => {
    return {
        type: GET_MODAL,
        payload: {
            type:'modal'
        }
    };
};

export { modal_action };