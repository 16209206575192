/**
 * @param {*} state
 * @param {*} action
 */
import {
    SEGMENT_APPROVE_SUCCESS, SEGMENT_CANCEL_SUCCESS,
    SEGMENT_CREATE_SUCCESS,
    SEGMENT_LIST_SUCCESS,
    SEGMENT_UPDATE_SUCCESS
} from "../../types";

const SegmentsReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case SEGMENT_LIST_SUCCESS:
            let segments = action.payload.data.data;

            segments.map((segment) => {
                let id = segment['segment_id'];
                state[id] = segment;
                return true;
            });

            return {...state, action};
        case SEGMENT_CREATE_SUCCESS:
            let new_id = action.payload.data['segment_id'];
            return {...state, action, new_id};
        case SEGMENT_UPDATE_SUCCESS:
        case SEGMENT_APPROVE_SUCCESS:
        case SEGMENT_CANCEL_SUCCESS:
            let id = action.payload.data['segment_id'];
            state[id] = action.payload.data;
            return {...state, action};
        default:
            return state;
    }
};

export {SegmentsReducer}