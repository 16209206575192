import {CLEAR_STORE} from "../../../types/index";

const is_edit_endpoint_action = (id) => {
    return {
        type: CLEAR_STORE,
        payload: {
            'code':0,
            'id':id,
            'is_edit_endpoint':true
        }
    };
};

export {is_edit_endpoint_action}