import {FANPAGE_REEL_CREATE_SUCCESS, FANPAGE_REEL_LIST_SUCCESS, FANPAGE_REEL_UPDATE_SUCCESS} from "../../../types";


/**
 * @param {*} state
 * @param {*} action
 */
const FanpageReelsReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    let fanpageId = action.payload.fanpage_id;
    if (fanpageId === undefined || fanpageId === null) return state;
    if (state[fanpageId] === undefined) state[fanpageId] = {};

    switch (action.type) {
        case FANPAGE_REEL_LIST_SUCCESS:
            let posts = action.payload.data.data;
            posts.map((post) => {
                let id = post['post_id'];
                state[fanpageId][id] = post;
                return true;
            });

            return {...state, action: action};
        case FANPAGE_REEL_UPDATE_SUCCESS:
        case FANPAGE_REEL_CREATE_SUCCESS:
            let payload = action.payload.data;
            let id = payload['post_id'];

            state[fanpageId][id] = payload;
            return {...state, action: action};
        default:
            return {...state, action: action};
    }
};

export {
    FanpageReelsReducer
}