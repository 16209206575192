import {SERVER_API_AUTH2} from '../../constans';
import {REGISTER_BY_EMAIL, FAILURE_REGISTER_BY_EMAIL, FANPAGE_EDIT_FAILURE} from '../../types';
import {RSAA} from 'redux-api-middleware';

/**
 * REGISTER_BY_EMAIL
 * @param {*} email
 * @param {*} pwd
 */
const registerByEmailAction = (data_target) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_AUTH2}/api/auth/signup`,
        method: 'POST',
        headers: (() => {
            return {"Content-Type": "application/json", "X-Requested-With": "XMLHttpRequest"};
        }),
        body: (() => {
            const {
                name, email, pwd, original_pwd
            } = data_target;

            const data = {
                "name": name,
                "email": email.toLowerCase(),
                "password": pwd,
                "password_confirmation": original_pwd,
            }
            return JSON.stringify(data)
        }),
        types: [
            'REQUEST-REGISTER_BY_EMAIL',
            {
                type: REGISTER_BY_EMAIL,
                payload: async (action, state, res) => {
                    const {
                        email
                    } = data_target;
                    var code = [];
                    const contentType = res.headers.get('Content-Type');
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            code = json.code
                        })
                        return {code: code, user: email}
                    }
                },
            },
            {
                type: FAILURE_REGISTER_BY_EMAIL,
                payload: (action, state, res) => {
                    return res.json()
                },
            }
        ]
    }

});

export {registerByEmailAction}