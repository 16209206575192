import {
    FANPAGE_ANSWER_LIST_SUCCESS,
    FANPAGE_ANSWER_CREATE_SUCCESS,
    ANSWER_DELETE_SUCCESS,
} from "../../../types";


/**
 * @param {*} state
 * @param {*} action
 */
const FanpageAnswersReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    let parent_id = action.payload.parent_id;
    if (parent_id === undefined || parent_id === null) return state;
    if (state[parent_id] === undefined) state[parent_id] = {};

    switch (action.type) {
        case FANPAGE_ANSWER_LIST_SUCCESS:
            let answers = action.payload.data.data;
            answers.map((answer) => {
                let id = answer['id'];
                state[parent_id][id] = answer;
                return true;
            });

            return {...state, action: action};
        case ANSWER_DELETE_SUCCESS: //TODO: delete item from state
        case FANPAGE_ANSWER_CREATE_SUCCESS:

            let payload = action.payload.data;
            let id = payload['id'];

            state[parent_id][id] = payload;
            return {...state, action: action};
        default:
            return {...state, action: action};
    }
};

export {FanpageAnswersReducer}