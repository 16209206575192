/**
 |--------------------------------------------------
 | CONFIG SERVER & PORT
 |--------------------------------------------------
 */
let BASE_URL = process.env.REACT_APP_BASE_URL;
let SERVER_API_AUTH2 = process.env.REACT_APP_SERVER_API_AUTH2;
let SERVER_API_PLATFORMJK = process.env.REACT_APP_SERVER_API_PLATFORMJK;
let SERVER_API_NOTIFY = process.env.REACT_APP_SERVER_API_NOTIFY;
let SERVER_EMAIL_NOTIFY = process.env.REACT_APP_SERVER_EMAIL_NOTIFY;
let SERVER_GG_STORE_REPLY = process.env.REACT_APP_SERVER_GOOGLE_REPLY;
let SERVER_API_FB_BOT = process.env.REACT_APP_SERVER_API_FB_BOT;
let SERVER_API_EMS = process.env.REACT_APP_SERVER_API_EMS;

export {
    BASE_URL,
    SERVER_API_AUTH2,
    SERVER_API_PLATFORMJK,
    SERVER_API_NOTIFY,
    SERVER_EMAIL_NOTIFY,
    SERVER_GG_STORE_REPLY,
    SERVER_API_FB_BOT,
    SERVER_API_EMS
}
