import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {CREATE_KEYWORD_FUNBOT, FAILURE_CREATE_KEYWORD_FUNBOT} from '../../../types/index';
import {RSAA} from 'redux-api-middleware';
import headers from "../../../utils/headers";

const create_keyword_action = (data_target) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_PLATFORMJK}/api/keywords`,
        method: 'POST',
        headers: (headers()),
        body: (() => {
            return JSON.stringify(data_target)
        }),
        types: [
            'REQUEST_CREATE_KEYWORD_FUNBOT',
            {
                type: CREATE_KEYWORD_FUNBOT,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        return res.json()
                    }
                },
            },
            {
                type: FAILURE_CREATE_KEYWORD_FUNBOT,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        return res.json()
                    }
                },
            },
        ]
    }

});

export {create_keyword_action}