import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {RSAA} from 'redux-api-middleware';
import {GET_APP_FUNBOT} from "../../../types/index";
import queryString from 'query-string';
import headers from "../../../utils/headers";

/**
 * GET_APP
 */
const get_app_action = (per_page = 100, page = 1, app_name = null, sort = "-created_at") => ({

    [RSAA]: {
        endpoint: () => {
            let parsed = {}
            app_name ? parsed = {
                'per_page': per_page,
                'page': page,
                'app_name': app_name,
                'sort': sort
            } : parsed = {
                'per_page': per_page,
                'page': page,
                'sort': sort
            }
            let query_param = queryString.stringify(parsed);
            return `${SERVER_API_PLATFORMJK}/api/v2/apps?${query_param}`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            'REQUEST_APP_FUNBOT',
            {
                type: GET_APP_FUNBOT,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        return res.json()
                    }

                }
            },
            'FAILURE_APP_FUNBOT'
        ]
    }

})

export {get_app_action}