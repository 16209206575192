/**
 * @param {*} state
 * @param {*} action
 */
import {APP_BY_KEY_LIST_SUCCESS} from "../../types";

const AppByKeysReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case APP_BY_KEY_LIST_SUCCESS:
            let apps = action.payload.data.data;

            apps.map((app) => {
                let id = app['app_id'];
                state[id] = app;
                return true;
            });

            return {...state, action};
        default:
            return state;
    }
};

export {AppByKeysReducer}