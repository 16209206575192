import {
    FANPAGE_EDIT_SUCCESS,
    FANPAGE_GET_USER_INBOX_SUCCESS,
    FANPAGE_POST_REVIEW_DETAIL_SUCCESS,
    FANPAGE_POST_REVIEW_LIST_SUCCESS, FANPAGE_POST_REVIEW_UPDATE_POST_STATUS_ERROR,
    FANPAGE_POST_REVIEW_UPDATE_POST_STATUS_SUCCESS,
    FANPAGE_POST_TOP_COMMENT_SUCCESS
} from "../../../types";
import {toast} from "react-toastify";
import {i18n} from "../../../language/i18n";


/**
 * @param {*} state
 * @param {*} action
 */
const FanpagePostsReviewReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case FANPAGE_POST_REVIEW_LIST_SUCCESS:
            return {...state, action: action};
        case FANPAGE_POST_REVIEW_DETAIL_SUCCESS:
            return {...state, action: action};
        case FANPAGE_POST_TOP_COMMENT_SUCCESS:
            return {...state, action: action};
        case FANPAGE_GET_USER_INBOX_SUCCESS:
            return {...state, action: action};
        case FANPAGE_EDIT_SUCCESS:
            return {...state, action: action};
        case FANPAGE_POST_REVIEW_UPDATE_POST_STATUS_SUCCESS:
            let id = action.payload.data['post_id'];
            state[id] = action.payload.data;
            return {...state, action: action};
        case FANPAGE_POST_REVIEW_UPDATE_POST_STATUS_ERROR:
            let message_update_post = action.payload.message;
            toast.error(i18n.t(message_update_post));
            return {...state, action};
        default:
            return {...state, action: action};
    }
};

export {
    FanpagePostsReviewReducer
}