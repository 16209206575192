/**
 * @param {*} state
 * @param {*} action
 */
import {
    SEGMENT_ADMIN_LIST_SUCCESS,
} from "../../types";

const SegmentsAdminReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case SEGMENT_ADMIN_LIST_SUCCESS:
            let segments = action.payload.data.data;

            segments.map((segment) => {
                let id = segment['segment_id'];
                state[id] = segment;
                return true;
            });

            return {...state, action};
        default:
            return state;
    }
};

export {SegmentsAdminReducer}