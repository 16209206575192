/**
|--------------------------------------------------
| CHECK DATA LOCAL
|--------------------------------------------------
*/
export default function issetFiled(filed_name) {
	try {
		const filed_value = localStorage.getItem(filed_name);
		if (
			filed_value !== null
			&& filed_value !== 'null'
			&& filed_value !== undefined
			&& filed_value !== 'undefined'
			&& filed_value !== ''
		) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
}
