import {
    INBOX_SUCCESS,
    INBOX_FAILURE,
    LIST_USER_OF_PAGE_SUCCESS,
    LIST_USER_OF_PAGE_FAILURE,
    LIST_MESSAGES_OF_USER_SUCCESS,
    LIST_MESSAGES_OF_USER_FAILURE,
    USER_LIST_SUCCESS,
    USER_LIST_FAILURE,
    ASSIGN_USER_SUCCESS,
    ASSIGN_USER_FAILURE,
    LIST_TAG_CONVERSATION_SUCCESS,
    LIST_TAG_CONVERSATION_FAILURE,
    LIST_TAG_SUCCESS,
    LIST_TAG_FAILURE,
    ADD_TAG_CONVERSATION_SUCCESS,
    ADD_TAG_CONVERSATION_FAILURE,
    STATUS_USER_UPDATE_SUCCESS,
    STATUS_USER_UPDATE_FAILURE,
    REPORT_CTV_SUCCESS,
    REPORT_CTV_FAILURE,
    REPORT_STAFF_SUCCESS,
    REPORT_STAFF_FAILURE,
    REPORT_TAG_SUCCESS,
    REPORT_TAG_FAILURE,
    REMOVE_TAGS_SUGGESTION_SUCCESS,
    REMOVE_TAGS_SUGGESTION_FAILURE,
    LIST_REPLY_STORE_SUCCESS,
    LIST_REPLY_STORE_FAILURE,
    REMOVE_REPLY_STORE_SUCCESS,
    REMOVE_REPLY_STORE_FAILURE,
    CREAT_REPLY_STORE_FAILURE,
    CREAT_REPLY_STORE_SUCCESS,
    UPDATE_REPLY_STORE_SUCCESS,
    UPDATE_REPLY_STORE_FAILURE,
    UPDATE_MULTI_CONVERSATION_SUCCESS,
    UPDATE_MULTI_CONVERSATION_FAILURE,
    REPORT_COLLAB_SUCCESS,
    REPORT_COLLAB_FAILURE,
    REPORT_DETAIL_GROUP_SUCCESS,
    REPORT_DETAIL_GROUP_FAILURE,
    GROUP_FANCHAT_LIST_SUCCESS,
    GROUP_FANCHAT_LIST_FAILURE,
    REMOVE_GROUP_SUCCESS,
    REMOVE_GROUP_FAILURE,
    CREAT_GROUP_SUCCESS,
    CREAT_GROUP_FAILURE,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_FAILURE,
} from '../types';

/**
 * @param {*} state
 * @param {*} action
 */
const InboxReducer = (state = {}, action) => {

    switch (action.type) {
        case INBOX_SUCCESS:
        case INBOX_FAILURE:
        case LIST_USER_OF_PAGE_SUCCESS:
        case LIST_USER_OF_PAGE_FAILURE:
        case LIST_MESSAGES_OF_USER_SUCCESS:
        case LIST_MESSAGES_OF_USER_FAILURE:
        case USER_LIST_SUCCESS:
        case USER_LIST_FAILURE:
        case ASSIGN_USER_SUCCESS:
        case ASSIGN_USER_FAILURE:
        case LIST_TAG_CONVERSATION_SUCCESS:
        case LIST_TAG_CONVERSATION_FAILURE:
        case LIST_TAG_SUCCESS:
        case LIST_TAG_FAILURE:
        case ADD_TAG_CONVERSATION_SUCCESS:
        case ADD_TAG_CONVERSATION_FAILURE:
        case STATUS_USER_UPDATE_SUCCESS:
        case STATUS_USER_UPDATE_FAILURE:
        case REPORT_CTV_SUCCESS:
        case REPORT_CTV_FAILURE:
        case REPORT_STAFF_SUCCESS:
        case REPORT_STAFF_FAILURE:
        case REPORT_TAG_SUCCESS:
        case REPORT_TAG_FAILURE:
        case REMOVE_TAGS_SUGGESTION_SUCCESS:
        case REMOVE_TAGS_SUGGESTION_FAILURE:
        case LIST_REPLY_STORE_SUCCESS:
        case LIST_REPLY_STORE_FAILURE:
        case REMOVE_REPLY_STORE_SUCCESS:
        case REMOVE_REPLY_STORE_FAILURE:
        case CREAT_REPLY_STORE_FAILURE:
        case CREAT_REPLY_STORE_SUCCESS:
        case UPDATE_REPLY_STORE_SUCCESS:
        case UPDATE_REPLY_STORE_FAILURE:
        case UPDATE_MULTI_CONVERSATION_SUCCESS:
        case UPDATE_MULTI_CONVERSATION_FAILURE:
        case REPORT_COLLAB_SUCCESS:
        case REPORT_COLLAB_FAILURE:
        case REPORT_DETAIL_GROUP_SUCCESS:
        case REPORT_DETAIL_GROUP_FAILURE:
        case GROUP_FANCHAT_LIST_SUCCESS:
        case GROUP_FANCHAT_LIST_FAILURE:
        case REMOVE_GROUP_SUCCESS:
        case REMOVE_GROUP_FAILURE:
        case CREAT_GROUP_SUCCESS:
        case CREAT_GROUP_FAILURE:
        case UPDATE_GROUP_SUCCESS:
        case UPDATE_GROUP_FAILURE:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export {
    InboxReducer
}