import {
    GET_ALL_USER,
    CLEAR_STORE,
    GET_ROLES,
    GET_DETAIL_USERS,
    ADD_ROLES_USER,
    FAILURE_ADD_ROLES_USER,
    REFRESH_TOKEN
} from "../types";

var initData = [];
const UsersReducer = (state = initData, action) => {
    switch (action.type) {
        case GET_ALL_USER:
            state = action.payload.data.data;
            return [...state];
        case GET_ROLES:
        case GET_DETAIL_USERS:
        case ADD_ROLES_USER:
        case FAILURE_ADD_ROLES_USER:
        case CLEAR_STORE:
        case REFRESH_TOKEN:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
}
export default UsersReducer;