import {
    MSG_SEGMENT_CREATE_SUCCESS, MSG_SEGMENT_LIST_SUCCESS,
} from "../../../types";

/**
 * @param {*} state
 * @param {*} action
 */
const SegmentMsgReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case MSG_SEGMENT_LIST_SUCCESS:
            let segments = action.payload.data.data;

            segments.map((segment) => {
                let id = segment['id'];
                state[id] = segment;
                return true;
            });

            return {...state, action};

        case MSG_SEGMENT_CREATE_SUCCESS:
            let id = action.payload.data['id'];
            state[id] = action.payload.data;
            return {...state, action};
        default:
            return state;
    }
};

export {SegmentMsgReducer}