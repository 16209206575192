import {SEND_MESSAGES_FAILURE, SEND_MESSAGES_SUCCESS} from "../../../types";

/**
 * @param {*} state
 * @param {*} action
 */
const SendMessagesReducer = (state = {}, action) => {

    switch (action.type) {
        case SEND_MESSAGES_SUCCESS:
        case SEND_MESSAGES_FAILURE:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export {
    SendMessagesReducer
}