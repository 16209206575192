import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {RSAA} from 'redux-api-middleware';
import {DETAIL_FANPAGE_FUNBOT} from "../../../types/index";
import headers from "../../../utils/headers";

/**
 * GET_DETAIL_FANPAGE
 */
const get_detail_fan_page_action = (page_id) => ({

    [RSAA]: {
        endpoint: () => {
            return `${SERVER_API_PLATFORMJK}/api/pages/${page_id}`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            'REQUEST_DETAIL_FANPAGE_FUNBOT',
            {
                type: DETAIL_FANPAGE_FUNBOT,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            data = json.data
                        });
                        return {
                            "code": 0,
                            "type": "getDetailFanpage",
                            "data": data
                        }
                    }

                }


                //     (action, state, res) => {
                //     const contentType = res.headers.get('Content-Type');
                //
                //     if (contentType && ~contentType.indexOf('json')) {
                //         return res.json()
                //     }
                // }
            },
            'FAILURE_DETAIL_FANPAGE_FUNBOT'
        ]
    }

})

export {get_detail_fan_page_action}