import {SERVER_API_FB_BOT} from '../../constans/index';
import {headers} from '../../utils/index';
import {RSAA} from 'redux-api-middleware';
import queryString from 'query-string';
import {
    GROUP_FANCHAT_LIST_FAILURE,
    GROUP_FANCHAT_LIST_REQUEST,
    GROUP_FANCHAT_LIST_SUCCESS
} from "../../types/index";

/**
 * GET_FANCHAT
 */
const GroupFanchatListAction = (userId = null, actor = null, page = 1, limit = 100, sort = "-updated_at") => ({

    [RSAA]: {
        endpoint: () => {
            let parsed = {
                'limit': limit,
                'page': page,
                'sort': sort
            };
            let query_param = queryString.stringify(parsed);
            return `${SERVER_API_FB_BOT}/api/users/${userId}/groups?${query_param}`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            GROUP_FANCHAT_LIST_REQUEST,
            {
                type: GROUP_FANCHAT_LIST_SUCCESS,
                payload: async (action, state, res) => {
                    let data = [];
                    await res.json().then((json) => {
                        data = json.data
                    });

                    return {
                        "code": 0,
                        "type": GROUP_FANCHAT_LIST_SUCCESS,
                        "data": data
                    }
                },
            },
            {
                type: GROUP_FANCHAT_LIST_FAILURE,
                payload: async (action, state, res) => {
                    let message = "";
                    await res.json().then((json) => {
                        message = json.message;
                    });

                    return {
                        "code": 1,
                        "type": GROUP_FANCHAT_LIST_FAILURE,
                        "message": message,
                        "data": [],
                    }
                },
            }
        ]
    }

});

export {GroupFanchatListAction}