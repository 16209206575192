import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {RSAA} from 'redux-api-middleware';
import {DETAIL_ENDPOINT_FUNBOT} from "../../../types/index";
import headers from "../../../utils/headers";

const get_detail_endpoint_action = (id) => ({

    [RSAA]: {
        endpoint: () => {
            return `${SERVER_API_PLATFORMJK}/api/endpoints/${id}`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            'REQUEST_DETAIL_ENDPOINT_FUNBOT',
            {
                type: DETAIL_ENDPOINT_FUNBOT,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        return res.json()
                    }
                }
            },
            'FAILURE_DETAIL_ENDPOINT_FUNBOT'
        ]
    }

})

export {get_detail_endpoint_action}