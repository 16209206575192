import {
    FANPAGE_POST_REVIEW_UPDATE_POST_STATUS_SUCCESS,
    FANPAGE_RANK_CREATE_ERROR,
    FANPAGE_RANK_CREATE_SUCCESS,
    FANPAGE_RANK_LIST_SUCCESS,
    FANPAGE_RANK_UPDATE_ERROR,
    RANK_FANPAGE_POST_CREATE_SUCCESS,
    RANK_FANPAGE_POST_LIST_SUCCESS, RANK_PAGE_DELETE_POST_ERROR,
    RANK_PAGE_DELETE_POST_SUCCESS,
    TRACKING_RANK_FANPAGE_LIST_SUCCESS
} from "../../../types";
import {toast} from "react-toastify";
import {i18n} from "../../../language/i18n";


/**
 * @param {*} state
 * @param {*} action
 */
const RankingReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case FANPAGE_RANK_LIST_SUCCESS:
            let ranks = action.payload.data.data;

            ranks.map((rank) => {
                let id = rank['rank_id'];
                state[id] = rank;
                return true;
            });

            return {...state, action};
        case FANPAGE_RANK_CREATE_SUCCESS:
            let rankId = action.payload.data['rank_id'];
            state[rankId] = action.payload.data;
            return {...state, action};
        case FANPAGE_RANK_CREATE_ERROR:
            let message = action.payload.message;
            toast.error(i18n.t(message));
            return {...state, action};
        case FANPAGE_RANK_UPDATE_ERROR:
            let message_update = action.payload.message;
            toast.error(i18n.t(message_update));
            return {...state, action};
        case RANK_FANPAGE_POST_LIST_SUCCESS:
            return {...state, action: action};
        case RANK_FANPAGE_POST_CREATE_SUCCESS:
            return {...state, action: action};
        case RANK_PAGE_DELETE_POST_SUCCESS:
            return {...state, action: action};
        case RANK_PAGE_DELETE_POST_ERROR:
            let message_delete = action.payload.message;
            toast.error(i18n.t(message_delete));
            return {...state, action};
        case TRACKING_RANK_FANPAGE_LIST_SUCCESS:
            return {...state, action: action};
        case FANPAGE_POST_REVIEW_UPDATE_POST_STATUS_SUCCESS:
            let id = action.payload.data['post_id'];
            state[id] = action.payload.data;
            return {...state, action: action};
        default:
            return {...state, action: action};
    }
};

export {
    RankingReducer
}