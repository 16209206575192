const vn = {
    "hello": "Chào bạn",
    "login": "Đăng nhập",
    "email": "Email",
    "password": "Mật khẩu",
    "register": "Đăng ký",
    "sign_in_to_your_account": "Đăng nhập vào tài khoản của bạn",
    "register_now": "Đăng ký ngay",
    "email_is_required": "Email không được để trống",
    "email_is_incorrect": "Email không được để trống",
    "password_must_be_at_least_6_characters": "Mật khẩu phải ít nhất 6 kí tự",
    "account_does_not_have_access": "Tài khoản không có quyền truy cập",
    "create_your_account": "Tạo tài khoản của bạn.",
    "user_name": "Tên của bạn",
    "repeat_password": "Nhập lại mật khẩu",
    "create_account": "Tạo tài khoản",
    "dashboard": "Bảng điều khiển",
    "facebook": "Facebook",
    "fanpage": "Fanpage",
    "fb_app": "Ứng dụng Facebook",
    "fb_keyword": "Từ khóa Facebook",
    "endpoint": "Điểm cuối",
    "notify": "Thông báo",
    "facebook_notify": "Thông báo Facebook",
    "sdk_notify": "Thông báo Sdk",
    "admin": "Quản trị",
    "users": "Người sử dụng",
    "roles": "Vai trò",
    "permission": "Quyền",
    "conversation": "Cuộc hội thoại",
    "home": "Trang chủ",
    "create": "Tạo mới",
    "edit": "Chỉnh sửa",
    "detail": "Chi tiết",
    "profile": "Hồ sơ",
    "web_notify": "Thông báo web",
    "page_name": "Tên trang",
    "search": "Tìm kiếm",
    "id": "Id",
    "page_id_from_facebook": "Id trang từ Facebook",
    "status": "Trạng thái",
    "action": "Hành động",
    "no_data": "Không có dữ liệu",
    "not_found": "Không tìm thấy dữ liệu",
    "app_name": "Tên ứng dụng",
    "app_id_from_facebook": "Id ứng dụng từ Facebook",
    "keyword": "Từ khóa",
    "do_you_want_to_delete": "Bạn có muốn xóa không",
    "ok": "Đồng ý",
    "cancel": "Hủy bỏ",
    "method": "Phương thức",
    "url": "Đường dẫn",
    "name": "Tên",
    "registered": "Đã đăng ký",
    "roles_name": "Tên vai trò",
    "admin_id": "Id quản trị",
    "client_id": "Id khách hàng",
    "admin_responded": "Quản trị viên đã trả lời",
    "all": "Tất cả",
    "true": "Đúng",
    "false": "Sai",
    "platform": "Nền tảng",
    "admin_started": "Quản trị viên bắt đầu",
    "start_message": "Bắt đầu tin nhắn",
    "last_message": "Tin nhắn cuối cùng",
    "update": "Sửa",
    "endpoint_and_press_enter": "Nhập điểm cuối và nhấn enter",
    "keyword_and_press_enter": "Nhập từ khóa và nhấn enter",
    "user_and_press_enter": "Nhập người dùng và nhấn enter",
    "page_name_is_required": "Tên trang là bắt buộc!",
    "page_id_from_facebook_is_required": "Yêu cầu id trang từ facebook là bắt buộc!",
    "edit_keywords_success": "Chỉnh sửa từ khóa thành công",
    "edit_endpoints_success": "Chỉnh sửa điểm cuối thành công",
    "delete_endpoints_success": "Xóa điểm cuối thành công",
    "edit_success": "Chỉnh sửa thành công",
    "create_success": "Tạo thành công",
    "type_keyword_and_press_enter": "Nhập từ khóa và nhấn enter",
    "submit": "Gửi đi",
    "headers": "Tiêu đề",
    "queries": "Truy vấn",
    "settings": "Cài đặt",
    "logout": "Đăng xuất",
    "Do you want to logout?": "Bạn muốn đăng xuất tài khoản này?",
    "user_name_is_required": "Tên người dùng là bắt buộc!",
    "keyword_is_required": "Từ khóa là bắt buộc!",
    "user_name_are_limited_to_6_to_14_characters": "Tên người dùng được giới hạn từ 6 đến 14 ký tự!",
    "password_is_required": "Mật khẩu là bắt buộc!",
    "you_need_to_confirm_the_password": "Bạn cần xác nhận mật khẩu!",
    "password_does_not_match": "Mật khẩu không hợp lệ!",
    "url_is_required": "Đường dẫn là bắt buộc!",
    "please_enter_the_correct_url_format": "Vui lòng nhập đúng định dạng đường dẫn!",
    "method_is_required": "Phương thức là bắt buộc!",
    "format_error_queries": "Định dạng truy vấn lỗi!",
    "format_error_headers": "Định dạng lỗi tiêu đề!",
    "no_suggestions": "Không có đề xuất!",
    "Pls check the status on Facebook": "Vui lòng kiểm tra trạng thái trên Facebook",
    "Text": "Văn bản",
    "please_enter_the_correct_email_format_@funtap.vn": "Vui lòng nhập đúng định dạng email @(funtap.vn|techlab.ai|9pay.vn)",
    "upload_file_success": "Tải tập tin thành công !",
    "file_is_not_supported_file_extension": "Tệp tin không được hỗ trợ định dạng !",
    "max_image_size_2MB": "Kích thước hình ảnh tối đa 2MB !",
    "fanpage_not_found": "Fanpage không tồn tại",
    "company": "Công ty",
    "getStarted": "Bắt đầu",
    "Register success": "Đăng ký thành công",
    "Title": "Tiêu đề",
    "List Post": "Danh sách bài viết",
    "app_id_from_partner": "Game",
    "collection_id_from_partner": "Tên Tập",
    "collect_time": "Ngày Tạo",
    "collect_status": "Trạng thái tạo",
    "collect_count": "Số lượng User",
    "notify_time": "Ngày Gửi",
    "notify_status": "Trạng thái gửi",
    "notify_sent": "Số lượng gửi",
    "notify_success": "Gửi thành công",
    "notify_error": "Gửi lỗi",
    "select_post_error":"vui lòng chọn một bài viết",
    "select_post":"chọn bài viết",

    //Report Logs
    "api": "Api",
    "type_api": "Type Api",
    "status_code": "Mã Trạng Thái",
    "service": "Service",
    "total": "Total Use",
    "Status report api": "Trạng thái báo cáo api",
    "Sort total api": "Sắp xếp tổng sử dụng api",
    "Time start report": "Thời gian bắt đầu báo cáo",
    "Time end report": "Thời gian kết thúc báo cáo",

    //Logs
    "log_name": "log Name",
    "input_param": "Input Param",
    "properties": "Properties",
    "created_at": "Date Logs",

    //Push Notify
    "trigger_id":"ID",
    "type":"Loại",
    "trigger_at":"Thời Gian Gửi",

    //Google Reply
    "game_name":"Tên Trò Chơi",
    "package_name":"Tên Gói",
    "game_type":"Game Type",

    "approve_status":"Trạng thái phê duyệt"
};

export {vn}