import {
    GET_FANPAGE_FUNBOT,
    CLEAR_STORE,
} from '../types';

/**
 * @param {*} state
 * @param {*} action
 */
const autocomplete_reducer = (state = {}, action) => {
    switch (action.type) {
        case GET_FANPAGE_FUNBOT:
        case CLEAR_STORE:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
}

export {
    autocomplete_reducer
}