import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {headers} from '../../../utils/index';
import {RSAA} from 'redux-api-middleware';
import queryString from 'query-string';
import {HEAR_REPORT_FAILURE, HEAR_REPORT_SUCCESS, HEAR_REPORT_REQUEST} from "../../../types";

/**
 * GET_FANPAGE_HEAR_REPORT
 */
const FanpageHearReportAction = (params = []) => ({

    [RSAA]: {
        endpoint: () => {
            let query_param = queryString.stringify(params);
            return `${SERVER_API_PLATFORMJK}/api/hears-report?${query_param}`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            HEAR_REPORT_REQUEST,
            {
                type: HEAR_REPORT_SUCCESS,
                payload: async (action, state, res) => {
                    let data = [];
                    await res.json().then((json) => {
                        data = json.data
                    });

                    return {
                        "code": 0,
                        "type": HEAR_REPORT_SUCCESS,
                        "data": data,
                    }
                },
            },
            {
                type: HEAR_REPORT_FAILURE,
                payload: (action, state, res) => {
                    return res.json()
                },
            }
        ]
    }

});

export {FanpageHearReportAction}