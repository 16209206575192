import {SERVER_API_PLATFORMJK} from '../../constans';
import {RSAA} from 'redux-api-middleware';
import {ADD_ROLES_USER,FAILURE_ADD_ROLES_USER} from "./../../types/index";
import headers from "../../utils/headers";

const add_roles_action = (data_target,id) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_PLATFORMJK}/api/user/${id}/roles`,
        method: 'PUT',
        headers: (headers()),
        body: (() => {
            return JSON.stringify(data_target)
        }),
        types: [
            'REQUEST_ADD_ROLES_USER',
            {
                type: ADD_ROLES_USER,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    //var data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        // await res.json().then((json) => {
                        //     data = json.data
                        // })
                        return {
                            "code": 0,
                            "type": "add_roles",
                        }
                    }
                }
            },
            {
                type: FAILURE_ADD_ROLES_USER,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let message = "";
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            message = json.message
                        })

                        return {
                            "code": 1,
                            "type": "add_roles",
                            "message": message
                        }
                    }
                }
            },
        ]
    }

})

export {add_roles_action}