import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {REQUEST_CREATE_ENDPOINT, CREATE_ENDPOINT, FAILURE_CREATE_ENDPOINT} from '../../../types/index';
import {RSAA} from 'redux-api-middleware';
import headers from "../../../utils/headers";

const create_endpoint_action = (data_target) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_PLATFORMJK}/api/endpoints`,
        method: 'POST',
        headers: (headers()),
        body: (() => {
            console.log(JSON.stringify(data_target));
            return JSON.stringify(data_target);
        }),
        types: [
            REQUEST_CREATE_ENDPOINT,
            {
                type: CREATE_ENDPOINT,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            data = json.data
                        });
                        return {
                            "code": 0,
                            "type": "create_endpoint",
                            "data": data
                        }
                    }

                }
            },
            {
                type: FAILURE_CREATE_ENDPOINT,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        return res.json()
                    }
                },
            },
        ]
    }

});

export {create_endpoint_action}