import {SERVER_API_NOTIFY} from "../../constans";
import {RSAA} from 'redux-api-middleware';
import {
    SEGMENT_GET_ONE_FAILURE,
    SEGMENT_GET_ONE_REQUEST,
    SEGMENT_GET_ONE_SUCCESS,
} from "../../types";
import headers from "../../utils/headers";

const SegmentGetOneAction = (segment_id) => ({

    [RSAA]: {
        endpoint: () => {
            return `${SERVER_API_NOTIFY}/segments/${segment_id}`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            SEGMENT_GET_ONE_REQUEST,
            {
                type: SEGMENT_GET_ONE_SUCCESS,
                payload: async (action, state, res) => {
                    let data = [];
                    await res.json().then((json) => {
                        data = json.data
                    });

                    return {
                        "code": 0,
                        "type": SEGMENT_GET_ONE_SUCCESS,
                        "data": data,
                    };
                }
            },
            {
                type: SEGMENT_GET_ONE_FAILURE,
                payload: (action, state, res) => {
                    return res.json()
                },
            }
        ]
    }

});

export {SegmentGetOneAction}