import {UPATE_FANPAGE_MENU_SUCCESS, UPDATE_FANPAGE_MENU_ERROR} from "../../../types";
import {toast} from "react-toastify";
import {i18n} from "../../../language/i18n";


/**
 * @param {*} state
 * @param {*} action
 */
const MenuReducer = (state = {}, action) => {

    switch (action.type) {
        case UPATE_FANPAGE_MENU_SUCCESS:
            return {...state, action};
        case UPDATE_FANPAGE_MENU_ERROR:
            let message = action.payload.message;
            toast.error(i18n.t(message));
            return {...state, action};
        default:
            return state;
    }
};

export default MenuReducer;