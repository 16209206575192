import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {
    FANPAGE_POST_CREATE_REQUEST,
    FANPAGE_POST_CREATE_SUCCESS,
    FANPAGE_POST_CREATE_ERROR,
} from '../../../types/index';
import {RSAA} from 'redux-api-middleware';
import headers from "../../../utils/headers";

const FanpagePostCreateAction = (data, fanpageId, actor = null) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_PLATFORMJK}/api/v2/pages/${fanpageId}/posts`,
        method: 'POST',
        headers: (headers()),
        body: (() => {
            return JSON.stringify(data)
        }),
        types: [
            FANPAGE_POST_CREATE_REQUEST,
            {
                type: FANPAGE_POST_CREATE_SUCCESS,
                payload: async (action, state, res) => {
                    let data = [];
                    await res.json().then((json) => {
                        data = json.data
                    });

                    return {
                        "code": 0,
                        "type": FANPAGE_POST_CREATE_SUCCESS,
                        "actor": actor,
                        "fanpage_id": fanpageId,
                        "data": data,
                    }
                },
            },
            {
                type: FANPAGE_POST_CREATE_ERROR,
                payload: (action, state, res) => {
                    return res.json()
                },
            },
        ]
    }

});

export {FanpagePostCreateAction}