import {SERVER_API_PLATFORMJK} from '../../../constans/index';
import {FAILURE_UPDATE_ENDPOINT, UPDATE_ENDPOINT} from '../../../types/index';
import {RSAA} from 'redux-api-middleware';
import headers from "../../../utils/headers";

const update_endpoint_action = (data_target, id) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_PLATFORMJK}/api/endpoints/${id}`,
        method: 'PUT',
        headers: (headers()),
        body: (() => {
            return JSON.stringify(data_target);
        }),
        types: [
            'REQUEST_UPDATE_ENDPOINT',
            {
                type: UPDATE_ENDPOINT,
                payload:async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    let data = [];
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            data = json.data
                        });
                        return {
                            "code": 0,
                            "type": "update_endpoint",
                            "data": data
                        }
                    }

                }
            },
            {
                type: FAILURE_UPDATE_ENDPOINT,
                payload: (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');

                    if (contentType && ~contentType.indexOf('json')) {
                        return res.json()
                    }
                },
            },
        ]
    }

});

export {update_endpoint_action}