import {HEAR_REPORT_SUCCESS} from "../../../types";

/**
 * @param {*} state
 * @param {*} action
 */
const HearReportReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case HEAR_REPORT_SUCCESS:
            return {...state, action};
        default:
            return state;
    }
};

export {HearReportReducer}