import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import {ToastContainer} from "react-toastify";
import {CheckIsLogin} from "./containers/Users/CheckIsLogin";
import FanpageHearsModal from "./views/Facebook/Fanpage/FanpageHearsModal";
import OverlayLoadingModal from "./views/Modals/OverlayLoadingModal";
import {LoadingSpinner} from './views/Loading/LoadingSpinner';


// import Echo from 'laravel-echo';
// window.io = require('socket.io-client');
//
// window.Echo = new Echo({
//     broadcaster: 'socket.io',
//     host: 'https://facebook-bot.notifun.com',
//     authEndpoint: "/broadcasting/auth"
// });
// console.log(window.Echo);
// window.Echo.channel(`channel.1111111`)
//     .listen('.ReceiveMessage', function (e) {
//         console.log(e);
//     });


// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const GoogleLogin = React.lazy(() => import('./views/Pages/GoogleLogin/GoogleLogin'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

    render() {
        return (
            <React.Suspense fallback={""}>
                <ToastContainer/>
                <OverlayLoadingModal/>
                <FanpageHearsModal/>

                <BrowserRouter>
                    <CheckIsLogin/>
                    <LoadingSpinner/>
                    <Switch>
                        <Route exact path="/login" name="Login Page"
                               render={props => <Login i18n={this.props.i18n} {...props}/>}/>
                        <Route exact path="/register" name="Register Page"
                               render={props => <Register i18n={this.props.i18n} {...props}/>}/>
                        <Route exact
                               path="/auth/google"
                               name="Login Google Page"
                               render={props => <GoogleLogin i18n={this.props.i18n} {...props}/>}
                        />
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
                        <Route path="/" name="Home"
                               render={props => <DefaultLayout i18n={this.props.i18n} {...props}/>}/>
                    </Switch>
                </BrowserRouter>
            </React.Suspense>
        );
    }
}

export default App;
